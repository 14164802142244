import {React, useEffect, useState} from 'react';
import OfferDisplayDelete from './OfferDisplayDelete';
import "../css/Blog.css";
import { motion } from "framer-motion";
import AdminPanel from './AdminPanel'
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SEO from '../components/SEO';

function OfferDisplay() {
  const [offers, setOffers] = useState([]);

  useEffect( ()=>{
    fetch('https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/offers').then(response => {
      response.json().then(offers => {
        setOffers(offers);
      });
    })
  }, []);

  return (
    <>
  <SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="Best quality auto branding company near me,
        Best quality auto branding company in bangalore,
        Best quality auto top branding company near me,
        Best quality auto hood branding company near me,
        Best quality mobile van branding company near me,
        Best quality mobile van branding company in bangalore,
        Best quality cab branding company near me,
        Best quality cab branding company near me,
        Best quality bus branding company near me,
        Best quality bus branding company near me,
        "
      />

        <AdminPanel/>
        <div className="admin-container">
            
            <h2>
            Offers
            </h2>
        
            <div className="query-cont">
                <div className="card" style={{height: "fit-content"}}>
                <motion.div className='row'> 
                {offers.length > 0 && offers.map(offer => (
                <OfferDisplayDelete {...offer}/> 
                
          ))} 
          {/* <FontAwesomeIcon icon= {faTrash}></FontAwesomeIcon> */}
            
        </motion.div>
                </div> 
            </div>
            </div>    
      {/* </motion.div> */}
    </>
  )
}

export default OfferDisplay
