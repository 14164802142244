import {  useState } from 'react';
import {Navigate} from 'react-router-dom';
import "../css/LoginPage.css";
import Swal from "sweetalert2";

import SEO from '../components/SEO';

function LoginPage({ setAccessAllowed }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [redirect, setRedirect]  = useState(false)

  const loginHandler = async(ev) =>{
    
    ev.preventDefault();
    try{
      const response = await fetch("https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/usertoken", {
      //  const response = await fetch("https://sjdinnovative.com/userLogin", {
        method: 'POST',
        body: JSON.stringify({email, password}),
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
      });
      if(response.ok){
        // this varibale is called from App.js
        setAccessAllowed(true);
        setRedirect(true);
        window.localStorage.setItem("isLoggedIn", true);
      }
      else if (response && response.status >= 400 && response.status < 500) {
        // Handle AnotherErrorType
        Swal.fire({
          title: "Error!",
          text: "Check Your Password",
          icon: "warning",
          customClass: {
            confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
        });
      }
    }catch(error){
      if (error instanceof TypeError) {
        // Handle TypeError
        Swal.fire({
          title: "Wrong Credentials!",
          text: "Refresh the page and enter right username!",
          icon: "error",
          customClass: {
            confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
        });
      } 
      else {
        // Handle other types of errors
        Swal.fire({
          title: "Error!",
          text: "Refresh Your Network!",
          icon: "warning",
          customClass: {
            confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
        });
      }
      }
  }

  if(redirect){
    return <Navigate to={"/admin"} />
  }

  return (
    <>

<SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="auto hood branding, cab branding in bangalore, best mobile van branding services, auto stickers, auto branding in bangalore, mobile van branding, tricycle branding,
        lookwalker activities, lookwalker branding at best price"
      />

    <div className="login-container">
        <div className="enquiry-box-container">
            <div className="enquiry-box">
                <div className="admin-Logo">
                    <img src="/images/logo.png" alt="SJ Dariyath Innovative Creations" />
                </div>
                <div className="contactForm">
              <form action="" onSubmit={loginHandler}>
                <div className="inputBox">
                  <i className="fa fa-user" aria-hidden="true"></i>
                  <input 
                    type="text" 
                    name="" 
                    required="required" 
                    value = {email}
                    onChange={ev => setEmail(ev.target.value)}
                  />
                  <span>Email</span>
                </div>
                <div className="inputBox">
                    <i className="fa fa-unlock-alt" aria-hidden="true"></i>
                  <input 
                    type="password" 
                    name="" 
                    required="required"
                    value= {password}
                    onChange={ev => setPassword(ev.target.value)}
                  />
                  <span>Password</span>
                </div>
                <div className="inputBox">
                  <input type="submit" name="" value="Log In" />
                </div>
              </form>
            </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default LoginPage
