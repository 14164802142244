import {React, useEffect, useState} from 'react';
import UserDisplayDelete from './UserDisplayDelete';
import { motion } from "framer-motion";
import AdminPanel from './AdminPanel'
import axios from 'axios';
import "../css/Users.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button } from "react-bootstrap";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SEO from '../components/SEO';

function Users() {

  const [users, setUsers] = useState([]);

  useEffect( ()=>{
    fetch('https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/getusers').then(response => {
      response.json().then(users => {
        setUsers(users);
      });
    })
  }, []);

  
  return (
    <>

<SEO
    title="Best Marketing and Advertising Company in Bangalore"
    description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
    keywords="college event,
    school events,
    society events
    rwa activities,
    atl activities,
    btl activities,
    atl and btl marketing,
    rwa society,
    techpark events,
    corporate events,
    "
  />

        <AdminPanel/>
        <div className="admin-container">
            
            <h2>
            Users
            </h2>
        
            <div className="query-cont">
                <div className="card">
                <motion.div className='column' > 
                {users.length > 0 && users.map(user => (
                <UserDisplayDelete {...user}/> 
                
          ))} 
          {/* <FontAwesomeIcon icon= {faTrash}></FontAwesomeIcon> */}
            
        </motion.div>
                </div> 
            </div>
            </div>    
      {/* </motion.div> */}
    </>
  )
}

export default Users
