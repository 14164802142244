import {React, useState} from 'react'
import { Link, Navigate } from 'react-router-dom'
import "../css/Admin.css"

import SEO from '../components/SEO';

function AdminPanel() {
  const [redirect, setRedirect] = useState(false);

  const HandleLogout = async() =>{
    const response = await fetch("https://sjdinnovative.com/userLogout", {
    // const response = await fetch("/userLogout", {
      method:"POST",
      credentials: 'include'
    });
    if(response.ok){
      localStorage.removeItem('token');
      window.localStorage.removeItem("isLoggedIn");
      setRedirect(true);
    }
  }

  if(redirect){
    return <Navigate to={'/login'} />;
  }

  return (
    <>    

<SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="best btl marketing agency in bangalore, Top Advertising Agencies For Bus in Bangalore, Bus Advertising in India at Best Rates,
        RWA Activity Services at best price in bangalore, Top Quality LED Look Walker, List of Best Look Walker Companies in Bengaluru,
        Top In Shop Branding Services in Bangalore"
      />

    <div className="admin-panel">
          <div className="admin-Logo">
            <img src="/images/logo.png" alt="SJ Dariyath Innovative Creations" />
          </div>
          <input type="checkbox" name="" id="checker" />

      <label htmlFor="check" className="icons">
       <i className="fa fa-bars" id="menu-icon"></i>
      <i className="fa fa-times" id="close-icon"></i>
      </label>
      
          <div  className='admin-functions'>
            <ul>
              <li><Link to="/Admin" className='link'>Admin</Link></li>
              <li><Link to="/FeedbackReceived" className='link'>Feedback</Link></li>
              <li><Link to="/QueryReceived" className='link'>Query Received</Link></li>
              {/* <li><Link to="/CreateUser" className='link'>Users</Link></li> */}
              <li><Link to="/Blogposter" className='link'>Blog Poster</Link></li>
              <li><Link to="/OfferPoster" className='link'>Offer Poster</Link></li>
              <li><a className='link' onClick={HandleLogout}>Log Out</a></li>
            </ul>
          </div>
        </div>
    
    </>
  )
}

export default AdminPanel