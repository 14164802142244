import React, { useEffect } from 'react';
import '../css/Navbar.css';
import '@fortawesome/fontawesome-free/css/all.css';
import Headroom from 'react-headroom';
import {cursorInitialiser} from './Cursor';
import { Link } from "react-router-dom";

import SEO from '../components/SEO';

const Navbar = () => {
  useEffect(() => {
    const cleanupCursor = cursorInitialiser('.navbar-link');

    // Cleanup function
    return () => {
      cleanupCursor();
    };
  }, []);

  const headroomStyle = {
    background: 'rgb(128,128,128)', // Replace 'yourColorHere' with the desired color value
  };

  return (
    <>

<SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="Best quality auto branding company near me,
        Best quality auto branding company in bangalore,
        Best quality auto top branding company near me,
        Best quality auto hood branding company near me,
        Best quality tricycle branding company near me,
        Best quality tricycle branding company in bangalore,
        Best quality lookwalker activity company near me,
        Best quality lookwalker branding company near me,
        Best quality metro branding company near me,
        Best quality metro branding company near me,
        "
      />

      <Headroom style={headroomStyle}>
        <header className="header">
          <div className="logo">
            <img src="/images/logoo.png" alt="SJ Dariyath Innovative Creations" className='Logo' />
          </div>

            <input type="checkbox" name="" id="checker" />

          <label htmlFor="check" className="icons">
            
            <i className="fa fa-bars" id="menu-icon"></i>
            <i className="fa fa-times" id="close-icon"></i>

          </label>

          <nav className='navbar'>
            <Link to="/Home" style={{ "--i": 0 }} className='navbar-link'>Home</Link>
            <Link to="/Products" style={{ "--i": 1 }} className='navbar-link'>Products</Link>
            <Link to="/Services" style={{ "--i": 2 }} className='navbar-link'>Services</Link>
            {/* <Link to="/DigitalMarketing" style={{ "--i": 4 }} className='navbar-link'>Digital Marketing</Link> */}
            {/* <Link to="/NewProducts" style={{ "--i": 5 }} className='navbar-link'>Offers</Link> */}
            <Link to="/Blog" style={{ "--i": 6 }} className='navbar-link'>Our Blogs</Link>
            <Link to="/About" style={{ "--i": 3 }} className='navbar-link'>About</Link>
            <Link to="/Awards" style={{ "--i": 5 }} className='navbar-link'>Awards & Recognition</Link>
            <Link to="/Contact" style={{ "--i": 4 }} className='navbar-link'>Contact</Link>
          </nav>
        </header>
        <div className="cursor"></div>
      </Headroom>
    </>
  );
};

export default Navbar;