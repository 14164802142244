import {React, useState} from 'react'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import "../css/GoogleLog.css"
import { jwtDecode } from "jwt-decode";

function GoogleLog({setIslogged, setVisitorAccess}) {
  const [credentialResponse, setCredentialResponse] = useState('')

  const GoogleLogHandler = (response) =>{
    setCredentialResponse(jwtDecode(response.credential));
    // console.log(credentialResponse)
    
    // const visitorDoc = jwtDecode(credentialResponse.credential);
                const res = fetch("http://localhost:4000/visitorData", {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(credentialResponse)
                })
                .catch(error => {
                  console.error('Error:', error);
                });
                // this will close the google login button
                setIslogged(false);  
    }                      

  const handleGoogleLoginError = () => {
    console.log('Login Failed');
  };

return (
    <>

        <div id="loginPrompt" className="login-prompt">
            <div className="login-content">
                <h2>Welcome!</h2>
                <p>Please login to continue.</p>
            <GoogleOAuthProvider clientId="910013616037-itk30dacj20jr0m98lhca5giaojtp92v.apps.googleusercontent.com">
            <GoogleLogin
                onSuccess={GoogleLogHandler}
                // must send a string 
                onError={handleGoogleLoginError}
            />
        </GoogleOAuthProvider>
        </div>
        </div>
        
    </>
  )
}

export default GoogleLog
