import {React, useEffect, useState, createClass} from 'react'
import "../css/NewProducts.css"
import axios from 'axios';
// import offer1 from "../components/Offer/offer1.jpeg";
// import offer2 from "../components/Offer/offer2.jpeg";
// import offer3 from "../components/Offer/offer3.jpg";
// import offer4 from "../components/Offer/offer4.jpg";
// import offer5 from "../components/Offer/offer5.jpg";
import {Link} from 'react-router-dom'
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SEO from '../components/SEO';

  const OfferDisplayDelete = ({_id, title, description, cover }) => {

    const [offers, setOffers] = useState([]);

    useEffect( ()=>{
      fetch('https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/offers').then(response => {
        response.json().then(offers => {
          setOffers(offers);
        });
      })
    }, []);

    
    const deleteOffer = ((title)=>{
      if(window.confirm("Do you want to remove?")){
          fetch(`https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/deleteoffers/title?title=${title}`,
          {method:"DELETE"}).then(()=>{
    
            console.log('Offer deleted successfully');
            window.top.location.reload();

          }).catch((err)=>{
              console.log(err.message)
          })
      }
    })

    
    return (
      <>
 <SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="Best quality auto branding company near me,
        Best quality auto branding company in bangalore,
        Best quality auto top branding company near me,
        Best quality auto hood branding company near me,
        Best quality mobile van branding company near me,
        Best quality mobile van branding company in bangalore,
        Best quality cab branding company near me,
        Best quality cab branding company near me,
        Best quality bus branding company near me,
        Best quality bus branding company near me,
        "
      />
  
        <div className="offer-container">
          <div className="thumbnail bg-white p-5" style={{height: "60px"}}>
            <div className="thumbnails-img" style={{height: "200px", width: "200px", marginRight:"20px"}} >
              <div className="img-container">
                <img src={cover} alt="offer-img" />
              </div>			
            </div>
            {/* <!--/.thumbnail-img--> */}
            <div className="caption"  style={{textAlign:"center"}}>
              <div className="offer-txt">
                  <h2>{title}</h2>
                <p>{description}</p>
              </div>
              
              <div>         
                <FontAwesomeIcon icon= {faTrash} type="button" onClick={() => deleteOffer(title)} ></FontAwesomeIcon>
                </div>
              {/* <!--/.blog-txt--> */}
            </div>
            {/* <!--/.caption--> */}
          </div>
          {/* <!--/.thumbnail--> */}
        </div>
        {/* <!--/.col--> */}
      </>
    );
  };



export default OfferDisplayDelete