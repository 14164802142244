import {React, useEffect, useState} from 'react';
import Navbar from './Navbar';
import BlogBody from './BlogBody';
import "../css/Home.css";
import "../css/Blog.css";
import { motion } from "framer-motion";
import ReactGA from "react-ga";

import SEO from '../components/SEO';
 
function Blog() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  },[]);
  const [posts, setPosts] = useState([]);

  useEffect( ()=>{
    fetch('https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/blogs').then(response => {
      response.json().then(posts => {
        setPosts(posts);
      });
    })
  }, []);

  return (
    <>

<SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="best btl marketing agency in bangalore, Top Advertising Agencies For Bus in Bangalore, Bus Advertising in India at Best Rates,
        RWA Activity Services at best price in bangalore, Top Quality LED Look Walker, List of Best Look Walker Companies in Bengaluru,
        Top In Shop Branding Services in Bangalore"
      />

        <Navbar/>

        {/* <motion.div
        initial={{ opacity: 0, y: "-100vh" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "-100vh" }}
        transition={{ duration: 0.5 }}
      > */}
        <header className='heading'>
            <h1 className='text-center'>Learn more about Marketing & Advertisements!!</h1>
        </header>

        <motion.div className='row'>        
          {posts.length > 0 && posts.map(post => (
            <BlogBody {...post}/>
          ))}
        </motion.div>

      {/* </motion.div> */}
    </>
  )
}

export default Blog
