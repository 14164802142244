import React from 'react'
import {Link} from "react-router-dom"
import "../css/PageNotFound.css";

import SEO from '../components/SEO';

function PageNotFound() {
  return (
    <>
  <SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="Best quality auto branding company near me,
        Best quality auto branding company in bangalore,
        Best quality auto top branding company near me,
        Best quality auto hood branding company near me,
        Best quality tricycle branding company near me,
        Best quality tricycle branding company in bangalore,
        Best quality lookwalker activity company near me,
        Best quality lookwalker branding company near me,
        Best quality metro branding company near me,
        Best quality metro branding company near me,
        "
      />	  
  
        <div className="cont-404">
            <div className="svg-cont">
                <img src="/images/404.svg" alt="" />
            </div>  
            <Link to="/Home" id="backHome">          
                <input type="button" value="Back To Home" className='sub-but' />
            </Link>
        </div>
    </>
  )
}

export default PageNotFound
