import {React, useEffect, useState} from 'react'
import AdminPanel from './AdminPanel'
import traffic1 from "../components/Analytics/traffic1.jpg";
import traffic3 from "../components/Analytics/traffic3.jpg";
import traffic4 from "../components/Analytics/traffic4.jpg";
import traffic5 from "../components/Analytics/traffic5.jpg";
import { Link } from 'react-router-dom'
import "../css/Admin.css";

import SEO from '../components/SEO';

const Admin = () => {

  const images = [
    traffic1,
    traffic3,
    traffic4,
    traffic5,
  ];

  const [currentImage, setCurrentImage] = useState(0);

  const changeImage = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  useEffect(() => {
    const timeoutId = setTimeout(changeImage, 3000); // Change image every 3000 milliseconds (3 seconds)

    return () => clearTimeout(timeoutId); // Cleanup timeout on component unmount
  }, [currentImage, images.length]);



  return (
    <>

    <SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="best btl marketing agency in bangalore, Top Advertising Agencies For Bus in Bangalore, Bus Advertising in India at Best Rates,
        RWA Activity Services at best price in bangalore, Top Quality LED Look Walker, List of Best Look Walker Companies in Bengaluru,
        Top In Shop Branding Services in Bangalore"
      />

        <AdminPanel/>


        <div className="admin-container">
          <h2>Welcome Admin !</h2>
          <h2>
          <div className='createuser' ><Link to="https://analytics.google.com/analytics" style={{fontSize: "18px"}}>
            Redirect to Google Analytics</Link></div>
        </h2>

          <div className="query-cont">
                <div className="card"></div>
            </div>

          <div className="analytics-cont">
          <div className="analytics">
            <img src={images[currentImage]} alt={`Slide ${currentImage + 1}`} width="400" height="400"/>
          </div>
        </div>
        
          {/* <h1>Admin will have to login before entering this page</h1> */}          
          {/* <p>This is the admin page. Only logged in users with the "admin" role
              should see this content.
              
              Todo List: <br />
              1. Create Page Not Found
        */}

        </div>
    </>
  )
}

export default Admin
