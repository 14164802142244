import React from "react";
import "../css/Blog.css";
import {Link} from 'react-router-dom'
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SEO from '../components/SEO';

const BlogDisplaDelete = ({_id, title, summary, cover, content }) => {

  const deleteBlog = ((title)=>{
    if(window.confirm("Do you want to remove?")){
        fetch(`https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/deleteblogs/title?title=${title}`,
        {method:"DELETE"}).then(()=>{
  
          console.log('Data deleted successfully');
          window.location.reload();
  
        }).catch((err)=>{
            console.log(err.message)
        })
    }
  })

  return (
    <>

<SEO
    title="Best Marketing and Advertising Company in Bangalore"
    description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
    keywords="signages, lollipop flange boards, standees, best canopies, blockout, pamphlets, neon sign boards,
    flex boards, sandwich boards, Pillar Branding"
  />

<div className="post-container">
        <div className="thumbnail bg-white p-5">
          <div className="thumbnail-img">
            <h3>
              Dariyath Blogs <span>Since 2016</span>
            </h3>
            <div className="img-cont">
              <Link to={`/blog/${_id}`}>
                <img src = {cover} alt="blog-img" />
              </Link>
            </div>
          </div>
          {/* <!--/.thumbnail-img--> */}
          <div className="caption">
            <div className="blog-txt">
              <Link to={`/blog/${_id}`}>
                <h5>{title}</h5>
              </Link>
              
              <Link to={`/blog/${_id}`}>Read More</Link>
            </div>

            <div>         
                <FontAwesomeIcon icon= {faTrash} type="button" onClick={() => deleteBlog(title)} ></FontAwesomeIcon>
            </div>

            {/* <!--/.blog-txt--> */}
          </div>
          {/* <!--/.caption--> */}
        </div>
        {/* <!--/.thumbnail--> */}
      </div>
      {/* <!--/.col--> */}
    </>
  );
};


export default BlogDisplaDelete;
