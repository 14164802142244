import {React, useEffect, useState} from 'react';
import Navbar from './Navbar';
import '../css/About.css'
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import slide_image_1 from "./Clients/easy pharmeasy.png"
import slide_image_2 from "./Clients/dabur.png"
import slide_image_3 from "./Clients/itc.png"
import slide_image_4 from "./Clients/johnson.png"
import slide_image_5 from "./Clients/milkbasket.png"
import slide_image_6 from "./Clients/practo.png"
import slide_image_7 from "./Clients/sunvik.webp"
import slide_image_8 from "./Clients/nobroker.png"
import slide_image_9 from "./Clients/zepto.jpeg"
import slide_image_10 from "./Clients/tata.png"
import slide_image_11 from "./Clients/swiggy.png"
import slide_image_12 from "./Clients/stanza.png"
import slide_image_13 from "./Clients/financepng.png"
import slide_image_14 from "./Clients/himalaya.png"
import slide_image_15 from "./Clients/sunvik.png"
import slide_image_16 from "./Clients/apollo.jpg"
import slide_image_17 from "./Clients/myntra.png"
import slide_image_18 from "./Clients/bikaji.png"
import slide_image_19 from "./Clients/kurlon.jpg"
import slide_image_20 from "./Clients/jain housing.jpeg"
import slide_image_21 from "./Clients/pizzahut.png"
import slide_image_22 from "./Clients/Aashirvaad.png"
import slide_image_23 from "./Clients/bajaj.png"
import slide_image_24 from "./Clients/bigbasket.jpg"
import slide_image_25 from "./Clients/intel.png"
import slide_image_26 from "./Clients/myroomie.jpg"
import slide_image_27 from "./Clients/nanduskitchen.png"
import slide_image_28 from "./Clients/ola.png"
import ReactGA from "react-ga";

import muskanImage from './Review/muskan.png';
import shabazImage from './Review/shabaz.png';
import noorImage from './Review/noor.png';
import priyankImage from './Review/priyank.png';

import placeholder from './Review/placeholder.png';

import team from "./Sample/team.jpg"

import indiamart from "../components/Analytics/indiamart.png"
import exportindia from "../components/Analytics/exportindia.png"

import SEO from '../components/SEO';
import Testimonials from '../components/Testimonials';
import { text } from '@fortawesome/fontawesome-svg-core';

const About = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  },[]);


const testimonials = [
  {
    text: "We got best quality tshirt print for a Sobha lake garden in KR puram within a 2 days of order. They delivered our product on the said time and the packing was good too. I suggest people who are looking for t shirt printing for school's college or office just a call away.",
    client: "Muskan",
    image: muskanImage,
    rating: 5
  },
  {
    text: "Very Professional and Work Commited as required.",
    client: "Ashish Kumar Sharma",
    image: placeholder,
    rating: 5
  },
  {
    text: "Good communication with the client throughout the process. High-quality and creative products delivered. Timely completion and deployment of the advertising campaign.",
    client: "Shabaz Razaa",
    image: shabazImage,
    rating: 5
  },
  {
    text: "Pull up your branding next level SJ dariyath Innovative providing best and quality products and services.",
    client: "Murulidhara K",
    image: placeholder,
    rating: 5
  },
  {
    text: "We order 10 Standee for our petroleum ads they delivered in 2 hours, I'm impressed 👍 Ahmed work more on print quality!!.",
    client: "Sangeeta",
    image: placeholder,
    rating: 5
  },
  {
    text: "Co-operative staff, end to end solution , follows ups and update until completion, honest and cost effective services. Overall very good services .👍",
    client: "Noor",
    image: noorImage,
    rating: 5
  },
  {
    client: "Priyank Goswami",
    image: priyankImage,
    rating: 5
  },
  {
    text: 'Good services. Very much satisfied.',
    client: "Asim Kumar Panda",
    image: placeholder,
    rating: 5
  },
  {
    client: "Wajeeha Banu",
    image: placeholder,
    rating: 5
  },
  {
    text: 'Nice quality t-shirts and printing 👌🏻',
    client: "Manoj Barik",
    image: placeholder,
    rating: 5
  },
  // Add more testimonials as needed
];

  return (
    <>
    <Navbar/>
    <motion.div className="about-wrapper"
       initial={{ opacity: 0, y: "-100vh" }}
       animate={{ opacity: 1, y: 0 }}
       exit={{ opacity: 0, y: "-100vh" }}
       transition={{ duration: 0.5 }}
    >

    <SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="auto hood branding, cab branding in bangalore, best mobile van branding services, auto stickers, auto branding in bangalore, mobile van branding, tricycle branding,
        lookwalker activities, lookwalker branding at best price"
      />  
        
        <div className="main-box">
            <div className="img-box">
                <center>
                    <p className="para">
                    <h1>Marketing Hub</h1>
                    <p>
                    Enhanced brand identification and remembrance is one of the main advantages of advertising.
                    A powerful company with a unique idea stands out from the competition and attracts clients.
                    Customers remember a product when they have a good experience with it. Because of their implicit
                    faith in the product, this will nudge them in the direction of purchasing the products/services.
                    </p>

                    </p>
                    
                   
                </center>
            </div>

            <div className="txt-box">
                <h1>About Us</h1>
                <p>
                    We offer our assistance in getting your business off the ground and will be there for
                    you when it becomes a brand. Fostering goodwill and product awareness among your client.
                    Promoting your company on both traditional and online channels. We regularly promote
                    your brand to build its trade name.
                    <br />
                    <br />
                    We began our adventure in 2016 by partnering with a few agencies who needed suppliers for
                    branding. As freelancers, we worked with agencies for two years. In an effort to expand
                    the movement, we began reaching out to start-ups and other businesses.
                    Within the allotted period, we have provided them with satisfactory services that meet
                    their expectations. Moving forward, we began internal production using our own machinery.
                    Our ability to provide reasonable costs to our clients is a result of our internal production.
                    We now employ a larger staff that is dedicated to our expansion and provides our services
                    throughout all over India.                </p>
                <Link to="/Contact" className='button text-black text-decoration-none p-2'>Contact Us</Link>                
            </div>

    <div className='socialmedia'>
      <div className="socials">

        <h4>Currently Accepting Followers😁 </h4>
                        <a href="https://www.linkedin.com/in/sjdariyathinnovativecreations/" target="_blank">
                          <i class="fa-brands fa-linkedin" aria-hidden="true"></i>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=100090053487478" target="_blank">
                          <i class="fa-brands fa-facebook-square" aria-hidden="true"></i>
                        </a>
                        <a href="https://www.youtube.com/channel/UC764zpQBqCPN_4bh6uH-TXw" target="_blank">
                          <i class="fa-brands fa-youtube-square" aria-hidden="true"></i>
                        </a>
                        <a href="https://www.instagram.com/sjdariyath/" target="_blank">
                          <i class="fa-brands fa-instagram-square" aria-hidden="true"></i>                          
                        </a>
                        <a href="https://in.pinterest.com/SJdariyath2innovative/" target="_blank">
                          <i class="fa-brands fa-pinterest-square" aria-hidden="true"></i>
                        </a>
                        <a href="https://www.indiamart.com/zariyathinnovativecreations/" target="_blank">
                          <img style={{width: "25px", height: "25px", marginTop: "-15px", marginLeft: "10px", marginRight: "10px"}}
                          src = {indiamart} ></img>
                        </a>
                        <a href="https://www.exportersindia.com/sj-dariyath-innovative-creations/" target="_blank">
                        <img style={{width: "25px", height: "25px", marginTop: "-15px", marginLeft: "10px", marginRight: "10px"}}
                        src = {exportindia} ></img>
                        </a>
                    </div>
      </div>

        </div>



        <div className="main-box client-container bg-white">

        <h2 className='text-center'>
            <b>Our Clients</b>
        </h2>
        
        <div className="service-pics clients">
              <div className="pics">
                <img src={slide_image_1} alt="" />
                
              </div>
              <div className="pics bg-white">
                <img src={slide_image_2} alt="" />
                
              </div>
              <div className="pics">
                <img src={slide_image_3} alt="" />
                
              </div>
              <div className="pics">
                <img src={slide_image_10} alt="" />
                
              </div>
              <div className="pics bg-white">
                <img src={slide_image_5} alt="" />
                
              </div>
              <div className="pics">
                <img src={slide_image_6} alt="" />
                
              </div>
              <div className="pics">
                <img src={slide_image_7} alt="" />
                
              </div>
              <div className="pics">
                <img src={slide_image_8} alt="" />
                
              </div>
              <div className="pics zepto-color">
                <img src={slide_image_9} alt="" />
                
              </div>
              <div className="pics">
                <img src={slide_image_4} alt="" />
                
              </div>
              <div className="pics">
                <img src={slide_image_11} alt="" />
                
              </div>
              <div className="pics">
                <img src={slide_image_12} alt="" />
                
              </div>
              <div className="pics">
                <img src={slide_image_13} alt="" />
                
              </div>
              <div className="pics">
                <img src={slide_image_14} alt="" />
                
              </div>
              <div className="pics">
                <img src={slide_image_15} alt="" />
                
              </div>
              <div className="pics">
                <img src= {slide_image_16} alt="" />
                
              </div>
              <div className="pics">
                <img src= {slide_image_17} alt="" />
                
              </div>
              {<div className="pics">
                <img src= {slide_image_18} alt="" />
              </div>}

              {<div className="pics">
                <img src= {slide_image_19} alt="" />
              </div>}

              {<div className="pics">
                <img src= {slide_image_20} alt="" />
              </div>}

              {<div className="pics">
                <img src= {slide_image_21} alt="" />
              </div>}

              {<div className="pics">
                <img src= {slide_image_22} alt="" />
              </div>}

              {<div className="pics">
                <img src= {slide_image_23} alt="" />
              </div>}

              {<div className="pics">
                <img src= {slide_image_24} alt="" />
              </div>}

              {<div className="pics">
                <img src= {slide_image_25} alt="" />
              </div>}

              {<div className="pics">
                <img src= {slide_image_26} alt="" />
              </div>}

              {<div className="pics">
                <img src= {slide_image_27} alt="" />
              </div>}

              {<div className="pics">
                <img src= {slide_image_28} alt="" />
              </div>}

          </div>

    </div>
    
    </motion.div>    



  <div class="team-intro">
  <h2>Meet Team  Dariyath</h2>
  <div className='main-box'>  
    
  <div class="team-container">
     {/*
    <div class="team-member member-top-left">
      <div class="member-info">
        <h3>Saddam Hussain</h3>
        <h4>City Manager</h4>
        <p>He drives our RWA operations in Bengaluru with a sharp focus on local markets and strategic leadership, fueling our growth and client satisfaction in the heart of the city.</p>
      </div>
    </div>
      */}

    {/*
    <div class="team-member member-bottom-left">
      <div class="member-info">
        <h3>Sultan Ahmed</h3>
        <h4>Operation Manager</h4>
        <p>With a keen eye for detail and process streamlining, he ensures smooth day-to-day operations, keeping our team running efficiently.</p>
      </div>
    </div>
    */}
 
    <div class="team-member">
      <div class="member-info">
      <h3>Nure Alam Fakir</h3>
        <h4>Graphic Designer, Social Media Manager</h4>
        <p>He designs eye-catching visuals and strategizes engaging digital content, driving cohesive brand experiences that captivate and convert.</p>
      </div>
    </div>


    <div class="team-member">
      <div class="member-info">
        <h3>Mansi Gupta</h3>
        <h4>Website Developer</h4>
        <p>She designs, implements, and maintains software solutions, ensuring seamless functionality across platforms, from user interfaces to databases, to keep operations running smoothly.</p>
      </div>
    </div>

    <div class="team-member">
      <div class="member-info">
        <h3>Anas Kazi</h3>
        <h4>Graphic Designer</h4>
        <p>Excels at creating captivating visuals that define our brand identity, from logos and marketing materials to web graphics and animations.</p>
      </div>
    </div>


    <div class="team-member">
      <div class="member-info">
        <h3>Mohd Ariz & Sajjad Hussain</h3>
        <h4>Quality Analyst</h4>
        <p>Oversees production, coordinates teams, and ensures quality, driving efficiency and minimizing downtime for consistent success.</p>
      </div>
    </div>


    <div class="team-member">
      <div class="member-info">
        <h3>Mohammad Anas</h3>
        <h4>Marketing Associate</h4>
        <p>Adept at analyzing market trends and pitching creative offline marketing solutions to clients. Their insights and enthusiasm drive our brand's success.</p>
      </div>
    </div>
    
    <div class="team-member">
      <div class="member-info">
        <h3>Arfath Pasha</h3>
        <h4>Machine Operator</h4>
        <p>With meticulous dedication, he ensures flawless prints from paper loading to settings adjustments, consistently delivering exceptional quality.</p>
      </div>
      </div>
      </div>

    <div class="central-element">
    <div class="member-info borderL">
        <h3>Sallahuddin Ahmed</h3>
        <h4>Founder, Business Head</h4>
        <p>The visionary leader behind SJ Dariyath. He inspires our team with his dedication to innovation, excellence, and customer satisfaction.</p>
      </div>

      <div class="member-info  borderR">
        <h3> Syed Juvirya Ahmed</h3>
        <h4>Co-founder, Business Head</h4>
        <p>Driving SJ Dariyath forward with passion for innovation and team development, ensuring our company thrives while prioritizing our team's well-being.</p>
      </div>
    </div>
   

  </div>
</div>

    <div className='main-box'>      
          <Testimonials testimonials={testimonials} />
    </div>
    </>
  )
}

export default About
