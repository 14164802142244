// cursorUtils.js
export const cursorInitialiser = (links) => {
    const cursor = document.querySelector('.cursor');
    const navLinks = document.querySelectorAll(links);
  
    const handleMouseMove = (e) => {
      let isOverNavLink = false;
  
      const navLinksArray = Array.from(navLinks);
  
      navLinksArray.forEach((navLink) => {
        if (navLink.contains(e.target)) {
          isOverNavLink = true;
        }
      });
  
      if (isOverNavLink) {
        cursor.style.left = e.pageX + 'px';
        cursor.style.top = e.pageY + 'px';
        cursor.style.display = 'block'; // Show cursor
      } else {
        cursor.style.display = 'none'; // Hide cursor
      }
    };
  
    document.addEventListener('mousemove', handleMouseMove);
  
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  };
  