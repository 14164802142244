import {React, useEffect, useState} from 'react';
import Navbar from './Navbar';
import NewProductBody from './NewProductBody';
import "../css/NewProducts.css"
import "../css/Blog.css";
import { motion } from "framer-motion";
import ReactGA from "react-ga";

import SEO from '../components/SEO';
  
function NewProducts() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  },[]);
  
  const [offers, setOffer] = useState([]);

  useEffect( ()=>{
    fetch('https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/offers').then(response => {
      response.json().then(offers => {
        setOffer(offers);
      });
    })
  }, []);

  return (
    <>
    
<SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="Standee,
        Signages,
        Canopy,
        pamphlets,
        blockout board,
        clip-on board,
        Lollipop flange board,
        gate arch,
        umbrella branding,
        pillar branding,
        led board,
        neno sign
        "
      />

    <Navbar/>

        {/* <motion.div
        initial={{ opacity: 0, y: "-100vh" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "-100vh" }}
        transition={{ duration: 0.5 }}
      > */}

        <motion.div className='row'>        
          {offers.length > 0 && offers.map(offer => (
            <NewProductBody {...offer}/>
          ))}
        </motion.div>

      {/* </motion.div> */}
    </>
  )
}

export default NewProducts
