import {React, useEffect, useState} from 'react';
import BlogDisplayDelete from './BlogDisplayDelete';
import "../css/Blog.css";
import { motion } from "framer-motion";
import AdminPanel from './AdminPanel'

import SEO from '../components/SEO';

function BlogDisplay() {
  const [posts, setPosts] = useState([]);

  useEffect( ()=>{
    fetch('https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/blogs').then(response => {
      response.json().then(posts => {
        setPosts(posts);
      });
    })
  }, []);

  return (
    <>
    <SEO
    title="Best Marketing and Advertising Company in Bangalore"
    description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
    keywords="signages, lollipop flange boards, standees, best canopies, blockout, pamphlets, neon sign boards,
    flex boards, sandwich boards, Pillar Branding"
  />
        <AdminPanel/>
        <div className="admin-container">
            
            <h2>
            Blogs
            </h2>
        
            <div className="query-cont">
                <div className="card">
                <motion.div className='row' > 
                
                {posts.length > 0 && posts.map(post => (
                <BlogDisplayDelete {...post}/> 
                
          ))} 
          {/* <FontAwesomeIcon icon= {faTrash}></FontAwesomeIcon> */}
            
        </motion.div>
                </div> 
            </div>
            </div>    
      {/* </motion.div> */}
    </>
  )
}

export default BlogDisplay
