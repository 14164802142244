import './App.css';
import '@fortawesome/fontawesome-free/css/all.css';
import Loader from './components/Loader';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import NewProducts from './components/NewProducts';
import Products from './components/Products';
import Services from './components/Services';
import Awards from './components/Awards';
import Blog from './components/Blog';
import Admin from './components/Admin';
import Users from './components/Users';
import Blogposter from './components/Blogposter';
import BlogDisplay from './components/BlogDisplay';
import SingleBlog from './components/SingleBlog.js';
import LoginPage from "./components/LoginPage";
import PageNotFound from "./components/PageNotFound";
import OfferPoster from "./components/OfferPoster";
import OfferDisplay from './components/OfferDisplay';
import FeedbackReceived from "./components/FeedbackReceived";
import QueryReceived from "./components/QueryReceived";
import AdvertisementQuery from "./components/AdvertisementQuery";
import ProductQuery from "./components/ProductQuery";
import ServiceQuery from "./components/ServiceQuery";
import CreateUser from "./components/CreateUser";
import FAQ from "./components/FAQ_Home.js";
import SEO from "./components/SEO";
// import DigitalMarketing from "./components/DigitalMarketing";
import { BrowserRouter as Router, Routes, Route, Navigate, Redirect } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import {useEffect, useState} from "react";
import axios from 'axios';
// import {LoginPage} from './components/LoginPage';
import ReactGA from "react-ga4";
import OfferDisplayDelete from './components/OfferDisplayDelete.js';

const TRACKING_ID = "G-P054MV39KH";
ReactGA.initialize(TRACKING_ID);

// Send pageview with a custom path
ReactGA.send(
  {
    hitType: "pageview",
    page: window.location.pathname,
    // title: "Custom Title"
  });

function App() {
  // this us for admin pages access
  const [accessAllowed, setAccessAllowed] = useState(false);
  // this is for visitors page access
  const [visitorAccess, setVisitorAccess] = useState(false);
  const [users,  setUsers] = useState([])  
  
  const loggedIn = window.localStorage.getItem("isLoggedIn");
  console.log(loggedIn, "login");
  
  return (
    <AnimatePresence>
      <Router>
        <Routes>
          {/* <Route index element={<Loader/>} /> 
            <Route path='/index.html' element={<Home/>}/> */}
          
          <Route index element={<Home/>} />
          <Route path='/index.html' element={<Home/>}/>
          <Route path='/Contact' element={<Contact/>}/>
          <Route path='/Products' element={<Products/>}/>
          <Route path='/Services' element={<Services/>}/>
          <Route path='/About' element={<About/>}/>
          <Route path='/Home' element={<Home/>}/>
          <Route path='/Awards' element={<Awards/>}/>
          <Route path='/Blog' element={<Blog/>}/>
          <Route path='/blog/:id' element={<SingleBlog />} />
          <Route path='/offer/:title' element={<OfferDisplayDelete />} />
          

          {/* <Route path='/NewProducts' element={<NewProducts/>}/> */}
          {/* <Route path='/DigitalMarketing' element={<DigitalMarketing/>}/> */}
         

          {/* all admin or office side access routes are here */}
          <Route path='/Admin' element={loggedIn ? <Admin /> : <Navigate to="/login" />} />
          <Route path='/Blogposter' element={loggedIn ? <Blogposter/> : <Navigate to="/login" />}/>
          <Route path='/BlogDisplay' element={loggedIn ? <BlogDisplay/> : <Navigate to="/login" />}/>
          <Route path='/Login' element={<LoginPage setAccessAllowed={setAccessAllowed} />}/>
          <Route path='/OfferPoster' element={loggedIn ? <OfferPoster /> : <Navigate to="/login" />}/>
          <Route path='/OfferDisplay' element={loggedIn ? <OfferDisplay/> : <Navigate to="/login" />}/>
          <Route path='/FeedbackReceived' element={loggedIn ? <FeedbackReceived /> : <Navigate to="/login" />}/>
          <Route path='/QueryReceived' element={loggedIn ? <QueryReceived/> : <Navigate to="/login" />}/>
          <Route path='/AdvertisementQuery' element={loggedIn ? <AdvertisementQuery/> : <Navigate to="/login" />}/>
          <Route path='/ProductQuery' element={loggedIn ? <ProductQuery/> : <Navigate to="/login" />}/>
          <Route path='/ServiceQuery' element={loggedIn ? <ServiceQuery/> : <Navigate to="/login" />}/>
          <Route path='/Users' element={loggedIn ? <Users /> : <Navigate to="/login" />} />
         <Route path='/CreateUser' element={loggedIn ? <CreateUser/> : <Navigate to="/login" />}/>

         <Route path='*' element={<PageNotFound/>}/>

        </Routes>
      </Router>   
    </AnimatePresence>     
    );
}

export default App;
