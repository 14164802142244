import { React, Suspense, useState, useEffect } from "react";
import Navbar from "./Navbar";
import "../css/Home.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "../css/About.css"
import "../css/Swal.css"
import AdHome from "./AdHome";
import Hero from "./Hero";
import GoogleLogin from "./GoogleLog";
import ReactGA from "react-ga";

import SEO from '../components/SEO';

 
const Home = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  },[]);

  //Google Login Button
  const [islogged, setIslogged] = useState(false); //change it to true when you want to render google log api



  return (
    <>
      <Navbar />

      <SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="Best quality auto branding company near me,
        Best quality auto branding company in bangalore,
        Best quality auto top branding company near me,
        Best quality auto hood branding company near me,
        Best quality tricycle branding company near me,
        Best quality tricycle branding company in bangalore,
        Best quality lookwalker activity company near me,
        Best quality lookwalker branding company near me,
        Best quality metro branding company near me,
        Best quality metro branding company near me,
        "
      />

      {islogged &&
            <GoogleLogin setIslogged={setIslogged} />
      }

      <motion.div
        // className="contanier-fluid"
        initial={{ opacity: 0, y: "-100vh" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "-100vh" }}
        transition={{ duration: 0.5 }}
      >        
      
        <div className="hero">

          <Hero/>

          <div className="heroPage">
            <div>
            <h2>Better Marketing Performance is ready for you!!</h2>
            <h1>Are You??</h1>

            {/* <h3 className="text-white">Your vision is our mission
                <br />
            </h3>  */}  

              <Link to="/About" className="button">Learn More</Link>
              
            </div>
          </div>
        </div>
      </motion.div>

      <AdHome/>

    </>
  );
};

export default Home;
