import {React, useState} from 'react'
import "../css/AdvertisementEnquiry.css";
import "../css/Home.css";
import Swal from "sweetalert2";
import Model from "react-modal";
import { redBorder } from '../functions/Validator';
import { changeBorder } from '../functions/Validator';
import FAQ from "./FAQ_Home";

import { Canvas, useThree, useFrame, extend } from "@react-three/fiber";
import { OrbitControls, Html } from "@react-three/drei";
import GoogleLog from "./GoogleLog";
import { Suspense, useEffect } from "react";

import auto2dSmallest from "../components/2D-Images/auto.png";
import auto2dSmall from "../components/2D-Images/auto.png";
import auto2dBig from "../components/2D-Images/auto.png";
import auto2dLarge from "../components/2D-Images/auto.png";

import cab2dSmallest from "../components/2D-Images/cab.png";
import cab2dSmall from "../components/2D-Images/cab.png";
import cab2dBig from "../components/2D-Images/cab.png";
import cab2dLarge from "../components/2D-Images/cab.png";

import van2dSmallest from "../components/2D-Images/mobilevan.png";
import van2dSmall from "../components/2D-Images/mobilevan.png";
import van2dBig from "../components/2D-Images/mobilevan.png";
import van2dLarge from "../components/2D-Images/mobilevan.png";

import tricycle2dSmallest from "../components/2D-Images/tricycle.png";
import tricycle2dSmall from "../components/2D-Images/tricycle.png";
import tricycle2dBig from "../components/2D-Images/tricycle.png";
import tricycle2dLarge from "../components/2D-Images/tricycle.png";

import rwa2dSmallest from "../components/2D-Images/rwa.png";
import rwa2dSmall from "../components/2D-Images/rwa.png";
import rwa2dBig from "../components/2D-Images/rwa.png";
import rwa2dLarge from "../components/2D-Images/rwa.png";

import window2dSmallest from "../components/2D-Images/window.png";
import window2dSmall from "../components/2D-Images/window.png";
import window2dBig from "../components/2D-Images/window.png";
import window2dLarge from "../components/2D-Images/window.png";

import demo2dSmallest from "../components/2D-Images/demo.png";
import demo2dSmall from "../components/2D-Images/demo.png";
import demo2dBig from "../components/2D-Images/demo.png";
import demo2dLarge from "../components/2D-Images/demo.png";

import lookwalker2dSmallest from "../components/2D-Images/lookwalker.png";
import lookwalker2dSmall from "../components/2D-Images/lookwalker.png";
import lookwalker2dBig from "../components/2D-Images/lookwalker.png";
import lookwalker2dLarge from "../components/2D-Images/lookwalker.png";

import seasonal2dSmallest from "../components/2D-Images/seasonal.png";
import seasonal2dSmall from "../components/2D-Images//seasonal.png";
import seasonal2dBig from "../components/2D-Images/seasonal.png";
import seasonal2dLarge from "../components/2D-Images/seasonal.png";

import bus2dSmallest from "../components/2D-Images/Bus/100-100.png";
import bus2dSmall from "../components/2D-Images/Bus/100-100.png";
import bus2dBig from "../components/2D-Images/Bus/100-100.png";
import bus2dLarge from "../components/2D-Images/Bus/100-100.png";

import metro2dSmallest from "../components/2D-Images/Metro/100-100.png";
import metro2dSmall from "../components/2D-Images/Metro/100-100.png";
import metro2dBig from "../components/2D-Images/Metro/100-100.png";
import metro2dLarge from "../components/2D-Images/Metro/100-100.png";

import auto01 from "../components/Sample/Auto/01.jpg";
import auto02 from "../components/Sample/Auto/02.jpeg";
import auto03 from "../components/Sample/Auto/03.jpg";
import auto04 from "../components/Sample/Auto/04.jpg";
import auto05 from "../components/Sample/Auto/05.jpeg";
import auto06 from "../components/Sample/Auto/06.jpg";

import cab01 from "../components/Sample/Cab/01.jpeg";

import van01 from "../components/Sample/Branding/mobilevan.jpeg";
import van02 from "../components/Sample/MobileVan/02.jpeg";
import van03 from "../components/Sample/MobileVan/03.jpeg";
import van04 from "../components/Sample/MobileVan/04.jpeg";
import van05 from "../components/Sample/MobileVan/05.jpeg";
import van06 from "../components/Sample/MobileVan/06.jpeg";

import tricycel01 from "../components/Sample/Tricycle/01.jpg";
import tricycel02 from "../components/Sample/Tricycle/02.jpeg";
import tricycel03 from "../components/Sample/Tricycle/03.jpeg";
import tricycel04 from "../components/Sample/Tricycle/04.jpeg";
import tricycel05 from "../components/Sample/Tricycle/05.jpeg";
import tricycel06 from "../components/Sample/Tricycle/06.jpeg";

import rwa01 from "../components/Sample/RWA/01.jpeg";
import rwa02 from "../components/Sample/RWA/02.jpeg";
import rwa03 from "../components/Sample/RWA/03.jpg";
import rwa04 from "../components/Sample/RWA/04.jpeg";
import rwa05 from "../components/Sample/RWA/05.jpeg";
import rwa06 from "../components/Sample/RWA/06.jpeg";

import window01 from "../components/Sample/Window/01.jpeg";
import window02 from "../components/Sample/Window/02.jpeg";
import window03 from "../components/Sample/Window/03.jpeg";
import window04 from "../components/Sample/Window/04.jpeg";
import window05 from "../components/Sample/Window/05.jpeg";
import window06 from "../components/Sample/Window/06.jpeg";

import demo01 from "../components/Sample/Demonstration/01.jpeg";
import demo02 from "../components/Services/College/05.jpg";
import demo03 from "../components/Sample/Demonstration/03.jpeg";
import demo04 from "../components/Services/TechPark/03.jpeg";
import demo05 from "../components/Sample/Demonstration/05.jpeg";
import demo06 from "../components/Sample/Demonstration/06.jpeg";

import lookwalker01 from "../components/Sample/Branding/lookwalker.jpeg";
import lookwalker02 from "../components/Sample/Lookwalker/02.jpeg";
import lookwalker03 from "../components/Sample/Lookwalker/03.jpeg";
import lookwalker04 from "../components/Sample/Lookwalker/04.jpeg";
import lookwalker05 from "../components/Sample/Lookwalker/05.jpeg";
import lookwalker06 from "../components/Sample/Lookwalker/06.jpeg";

import seasonal01 from "../components/Sample/Auto/04.jpg";
import seasonal02 from "../components/Sample/MobileVan/03.jpeg";
import seasonal03 from "../components/Sample/Seasonal/03.jpeg";
import seasonal04 from "../components/Sample/Seasonal/04.jpeg";
import seasonal05 from "../components/Sample/Seasonal/05.jpeg";
import seasonal06 from "../components/Sample/Seasonal/06.jpg";

import bus01 from "../components/Sample/Branding/bus.jpeg";
import comingsoon from "../components/Sample/coming soon.png";

import SEO from '../components/SEO';
import Accomplishments from '../components/Accomplishments';

const accomplishments = [
  {
    text: "8+ Years of Experience",
  },
  {
    text: "200+ Happy Clients all over India",
  },
  {
    text: "Team with 10+ Domain Expertise",
  },
  {
    text: "99% Success Rate",
  },
  {
    text: "Award Winning",
  },
  {
    text: "30+ Products and Services",
  },
  // Add more testimonials as needed
];

const AdHome = () => {
    // const [enquiryCall, setEnquiryCall] = useState(false);
    const [autoBrand, setAutoBrand] = useState(false);
    const [cabBrand, setCabBrand] = useState(false);
    const [vanBrand, setVanBrand] = useState(false);
    const [tricycleBrand, setTricycleBrand] = useState(false);
    const [rwaBrand, setRWABrand] = useState(false);
    const [windowBrand, setWindowBrand] = useState(false);
    const [demoBrand, setDemoBrand] = useState(false);
    const [lookwalkerBrand, setLookwalkerBrand] = useState(false);
    const [seasonalBrand, setSeasonalBrand] = useState(false);
    const [metroBrand, setMetroBrand] = useState(false);
    const [busBrand, setBusBrand] = useState(false);
    const [enquiryShow, setEnquiryShow] = useState(false);
  
    // form fields
    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [phone, setPhone] = useState('');
    const [adName, setAdName] = useState('');
    const [budget, setBudget] = useState('');
    const [details, setDetails] = useState('');
  
    // const handleEnquiryCall = () => {
    //   if (!enquiryCall) {
    //     setEnquiryCall(true);
    //   }
    // };
  
    // this handles preview of the category
    {/* const handleAutoBrand = () => {
      setAutoBrand(!autoBrand);
    }; */}

    // AdvertisementQuery Handler
    const AdSubmitHandler = async (ev) =>{
        var status = 0;        
        ev.preventDefault();
        // Form text verification
        if (status==0){
            const isTextValid = /^[A-Za-z\s]{4,}$/.test(name);
            const isMailValid = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/.test(mail);
            const isNumberValid = /^[6-9][0-9]{9}$/.test(phone);
            const isBudgetValid = /^[1-9][0-9]{1,8}$/.test(budget);
            const isDetailsValid = /^[a-zA-Z\s0-9.-]+$/.test(details);
            
            // Check if the textbox has valid name or not
            const textBox = document.getElementById("name");
            if (!isTextValid){     
            redBorder(textBox)                
            Swal.fire({
                title: "Validation!",
                text: "Name Must have minimum 4 letters. You cannot use numbers or any other special characters with name.",//"Error Ocurred. Try After Sometime",
                icon: "warning",
                customClass: {
                    confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
                }
            })               
            }else{
              changeBorder(textBox)
            }

            // check if the mail has valid mail address or not
            const mailBox = document.getElementById("email");
            if(!isMailValid){
              redBorder(mailBox) 
              Swal.fire({
                title: "Validation!",
                text: "Mail format is wrong. For Example: name@gmail.com",//"Error Ocurred. Try After Sometime",
                icon: "warning",
                customClass: {
                    confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
                }
            })                           
            }else{
              changeBorder(mailBox)
            }          

            // check if the number box is valid or not
            const phoneBox = document.getElementById("phone");
            if(!isNumberValid){
              redBorder(phoneBox)
              Swal.fire({
                title: "Validation!",
                text: "Phone number should start from 6-9. It should have 10 numbers. No letters are allowed.",//"Error Ocurred. Try After Sometime",
                icon: "warning",
                customClass: {
                    confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
                }
            })     
          }else{
              changeBorder(phoneBox)
            }

            // check if the budget box is valid or not
            const budgetBox = document.getElementById("budget");
            if(!isBudgetValid){
              redBorder(budgetBox)
              Swal.fire({
                title: "Validation!",
                text: "Letters and special characters are not allowed in budget.",//"Error Ocurred. Try After Sometime",
                icon: "warning",
                customClass: {
                    confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
                }
            })    
            }else{
              changeBorder(budgetBox)
            }

            // check if the requirement details box is valid or not
            const detailsBox = document.getElementById("details");
            if(!isDetailsValid){
              redBorder(detailsBox)
              Swal.fire({
                title: "Validation!",
                text: "You cannot use special characters in requirement details.",//"Error Ocurred. Try After Sometime",
                icon: "warning",
                customClass: {
                    confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
                }
            })
            }else{
              changeBorder(detailsBox)
            }
            
            if(isTextValid && isMailValid && isNumberValid && isBudgetValid && isDetailsValid){
              status=1;
            }
          }
          
          if(status==1){            
            try{
                const response = await fetch('https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/postadquery', {
                  method:'POST',
                  body: JSON.stringify({name,mail,phone,adName,budget,details}),
                  headers:{
                    "Content-Type": "application/json"
                  },
                  })
                  if(response.status === 200){
                    Swal.fire({
                      title: "Advertisement",
                      text: "Enquiry Submitted!",
                      icon: "success",
                      customClass: {
                        confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
                      }
                    });
                    setName('');
                    setMail('');
                    setPhone('');
                    setAdName('');
                    setBudget('');
                    setDetails('');
                    setEnquiryShow(false);                    
                  }else{
                    Swal.fire({
                      title: "Enquiry",
                      text: response.status,//"Oops! Enquiry Failed.",
                      icon: "warning",
                      customClass: {
                        confirmButton: 'custom-swal-button' //Apply custom class to the confirm button
                      }
                    });
                  }
              }catch(e){
                Swal.fire({
                  title: "Enquiry!",
                  text: e,//"Error Ocurred. Try After Sometime",
                  icon: "error",
                  customClass: {
                    confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
                  }
                });
              }
        }      
    };



  return (
    <>

<SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="Best quality auto branding company near me,
        Best quality auto branding company in bangalore,
        Best quality auto top branding company near me,
        Best quality auto hood branding company near me,
        Best quality mobile van branding company near me,
        Best quality mobile van branding company in bangalore,
        Best quality cab branding company near me,
        Best quality cab branding company near me,
        Best quality bus branding company near me,
        Best quality bus branding company near me,
        "
      />


    <div className="Advertisement-container">
      
    <Accomplishments accomplishments={accomplishments} />

          <div>
            <div className="heading bg-white">
              <h1>
                Different Kinds Of<p>Advertisements</p>
              </h1>
            </div>

            <div>
              {/* <input type="button" value="Open Model" onClick={()=>setEnquiryShow(true)}/> */}
              <Model
                isOpen={enquiryShow}
                className="enquiry-box-container centered-popup p-5"
              >
                {/* <div className="enquiry-box-container centered-popup"> */}
                <div className="enquiry-box">
                  <h2 className="text-center" style={{color: "red"}}>Enquire</h2>
                  <i
                    className="fa fa-times text-white close-btn enquiry-close"
                    aria-hidden="true"
                    onClick={() => setEnquiryShow(false)}
                  ></i>
                  <form action="" onSubmit={AdSubmitHandler}>
                    <div className="input">
                      <input 
                        type="text" 
                        name="name" 
                        id="name"
                        placeholder="Name" 
                        value={name}
                        required="true"
                        onChange={ ev => setName(ev.target.value)}
                      />
                    </div>
                    <div className="input">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email Address"
                        value={mail}
                        required="true"
                        onChange={ ev => setMail(ev.target.value) }
                      />
                    </div>
                    <div className="input">
                      <input
                       type="text" 
                       name="phone" 
                       id="phone"
                       placeholder="Phone Number"
                       value={phone}
                       required="true"
                       maxLength={10}
                       onChange={ ev => setPhone(ev.target.value)}
                       />
                    </div>
                    <div className="input">
                      <select name="adname" defaultValue="Branding" onChange={ ev => setAdName(ev.target.value)} required="true">
                        <option value="Auto Branding">Auto Branding</option>
                        <option value="Cab Branding">Cab Branding</option>
                        <option value="Mobile Van randing">Mobile Van Branding</option>
                        <option value="Tricycle Branding">Tricycle Branding</option>
                        <option value="RWA & Society">RWA & Society</option>
                        <option value="Window Display">Window Display</option>
                        <option value="Branding & emonstration">Branding & Demonstration</option>
                        <option value="Look Walker">Look Walker</option>
                        <option value="Seasonal & Festive Branding">Seasonal & festive Branding</option>
                        <option value="Bus Branding">Bus Branding</option>
                        <option value="Metro Branding">Metro Branding</option>
                      </select>
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        name="budget"
                        id="budget"
                        placeholder="Quote your budget in Rs."
                        value={budget}
                        required="true"
                        maxLength={5}
                        onChange={ ev => setBudget(ev.target.value)}
                      />
                    </div>
                    <div className="input" id="text-area">
                      <textarea
                        name="details"
                        id="details"
                        cols="30"
                        rows="1"
                        placeholder="Write all your requirements and queries.."
                        value={details}
                        required="true"
                        onChange={ ev => setDetails(ev.target.value)}
                      ></textarea>
                    </div>
                    <div className="input">
                      <input
                        type="submit"
                        value="Enquiry"
                        className="sub-but"
                      />
                    </div>
                  </form>
                </div>
                {/* </div> */}
              </Model>
            </div>
            

            <div className="ad-container container-position ad-containerGrey">
            
            <div className="ad-model">
                <div className='item'>
                  <img src={auto2dLarge} alt="#auto top branding" className='img'/>
                  <img src={auto2dBig} alt="#auto branding in bangalore" className='img'/>
                  {/* <img src={auto2dSmall} alt="#auto hood branding" className='img'/> */}
                  <img src={auto2dSmallest} alt="#auto rickshaw branding" className='img'/>
                </div>
              </div>

              <div className="model-description">
                <h2>
                  Auto Rickshaw Branding Service
                  <br />
                  <small>Stand out on the road.</small>
                </h2>
                <p className="p">
                  Transform your vehicle into a powerful brand statement with our expert auto branding services.
                  From custom wraps to eye-catching graphics, we help you make an impact wherever you go.
                  Auto rickshaw advertising is useful for various clients, right from luxury brands to local ones.
                  <br />
                  {/* <Link to="/AdvertisementEnquiry" className="blogLink"> */}
                  Discover the impact of our auto branding services - click to view 
                  <span
                    onClick={() => {
                      setAutoBrand(true);
                    }}
                    className="blogLink"
                  >
                    live preview
                  </span>
                    !!
                </p>
                <p className="p">
                  Considering
                  <em>
                    <i>
                      <b>auto branding</b>
                    </i>
                  </em>{" "}
                  for your product advertisement? Click below to learn more or to get in touch with our team!
                  <br />
                  <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
                  />
                </p>
              </div>
            <div>
            <Model
                isOpen={autoBrand}
                className="live-pic-container live-modal"
              >
                <i
                  className="fa fa-times close-btn"
                  aria-hidden="true"
                  onClick={() => setAutoBrand(false)}
                ></i>
                <h2 className="text-center">Sample Work</h2>
                <div className="product">
                  <div className="service-pics live-pics">
                    <div className="pics">
                      <img src={auto01} alt="auto rickshaw branding" />
                    </div>
                    <div className="pics">
                      <img src={auto02} alt="auto branding" />
                    </div>
                    <div className="pics">
                      <img src={auto03} alt="auto branding in bangalore" />
                    </div>
                    <div className="pics">
                      <img src={auto04} alt="auto hood branding" />
                    </div>
                    <div className="pics">
                      <img src={auto05} alt="auto top branding" />
                    </div>
                    <div className="pics">
                      <img src={auto06} alt="autobranding" />
                    </div>
                    
                  </div>
                </div>
              </Model>  
              
            </div>
            </div>

            <div className="ad-container container-position ad-containerWhite">   
              <div className="right-ad-model ">
              <div className="ad-model">
                <div className='item'>
                  <img src={cab2dLarge} alt="#DigitalLedBranding" className='img'/>
                  <img src={cab2dBig} alt="#DigitalLedBranding" className='img'/>
                   {/* <img src={cab2dSmall} alt="#DigitalLedBranding" className='img'/> */}
                  <img src={cab2dSmallest} alt="#DigitalLedBranding" className='img'/>
                </div>                
                </div>
              </div>

              <div className="model-description align-description">
                <h2>
                  Cab Branding Services
                  <br />
                  <small>Your Ride, Your Brand!</small>
                </h2>
                <p className="p">
                Make your logo travel the city, reaching potential customers wherever it goes.
                Cabs travel minimum 100 to 150 km in one shift. We use this opportunity to grab cutomer
                attention towards your brand and give effective brand promoting services in PAN India.
                  <br />
                  Discover the impact of our cab branding services - click to view 
                  <span
                    onClick={() => {
                      setCabBrand(true);
                    }}
                    className="blogLink"
                  >
                    live preview
                  </span>
                    !!
                </p>
                <p className="p">
                  Considering
                  <em>
                    <i>
                      <b>cab branding</b>
                    </i>
                  </em>{" "}
                  for your product advertisement? Click below to learn more or to get in touch with our team!
                  <br />
                  <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
                  />
                </p>
              </div>
              <div>
              <Model
                isOpen={cabBrand}
                className="live-pic-container live-modal"
              >
                <i
                  className="fa fa-times close-btn"
                  aria-hidden="true"
                  onClick={() => setCabBrand(false)}
                ></i>
                <h2 className="text-center">Sample Work</h2>
                <div className="product">
                  <div className="service-pics live-pics">
                    <div className="pics">
                      <img src={cab01} alt="cab branding" />
                    </div>
                    {/* 
                    <div className="pics">
                      <img src={cab01} alt="car branding" />
                    </div>
                    <div className="pics">
                      <img src={cab01} alt="cab branding in bangalore" />
                    </div>
                    <div className="pics">
                      <img src={cab01} alt="branding on car" />
                    </div>
                    <div className="pics">
                      <img src={cab01} alt="vehicle branding near me" />
                    </div>
                    <div className="pics">
                      <img src={cab01} alt="vehicle branding design" />
                    </div>
                    */}  
                  </div>
                </div>

                <div>
                      <h6 className="text-center">
                        <i><b>
                        Every day, we challange ourselves by taking on new tasks and endevours. More images COMING SOON, Stay Tuned !!
                        </b></i>
                      </h6>
                </div>

              </Model> 
            </div>
            </div>
            

            <div className="ad-container container-position ad-containerGrey">
              <div className="left-ad-model">
              <div className="ad-model">
                <div className='item'>
                  <img src={van2dLarge} alt="mobile van branding" className='img'/>
                  <img src={van2dBig} alt="mobile van advertising" className='img'/>
                  {/* <img src={van2dSmall} alt="led van advertising" className='img'/> */}
                  <img src={van2dSmallest} alt="tata ace mobile van advertising" className='img'/>
                </div>                
                </div>
              </div>

              <div className="model-description">
                <h2>
                  Mobile Van Branding Services
                  <br />
                  <small>Advertising that Moves Minds!</small>
                </h2>
                <p className="p">
                  Large hoodings with creative graphics attract human eyes specially when it is on a van
                  moving around the city.                    
                  We design and customize the route, and travel according to the client preferrence.
                  This helps attract large audience, particularly the targetted audience within the locality. 
                  <br />
                  Discover the impact of our mobile van branding services - click to view 
                  <span
                    onClick={() => {
                      setVanBrand(true);
                    }}
                    className="blogLink"
                  >
                    live preview
                  </span>
                    !!
                </p>
                <p className="p">
                  Considering
                  <em>
                    <i>
                      <b>mobile van branding</b>
                    </i>
                  </em>{" "}
                  for your product advertisement? Click below to learn more or to get in touch with our team!
                  <br />
                  <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
                  />
                </p>
              </div>
              <div>
              <Model
                isOpen={vanBrand}
                className="live-pic-container live-modal"
              >
                <i
                  className="fa fa-times close-btn"
                  aria-hidden="true"
                  onClick={() => setVanBrand(false)}
                ></i>
                <h2 className="text-center">Sample Work</h2>
                <div className="product">
                  <div className="service-pics live-pics">
                    <div className="pics">
                      <img src={van01} alt="tata ace mobile van advertising" />
                    </div>
                    <div className="pics">
                      <img src={van02} alt="led van advertising" />
                    </div>
                    <div className="pics">
                      <img src={van03} alt="mobile van advertising" />
                    </div>
                    <div className="pics">
                      <img src={van04} alt="mobile van branding" />
                    </div>
                    <div className="pics">
                      <img src={van05} alt="van branding" />
                    </div>
                    <div className="pics">
                      <img src={van06} alt="van branding mobile" />
                    </div>
                    
                  </div>
                </div>
              </Model> 
            </div>
            </div>

            <div className="ad-container container-position ad-containerWhite">
              <div className="right-ad-model">
              <div className="ad-model">
                <div className='item'>
                  <img src={tricycle2dLarge} alt="tricycle branding" className='img'/>
                  <img src={tricycle2dBig} alt="tricycle branding in bangalore" className='img'/>
                   {/* <img src={tricycle2dSmall} alt="tricycle advertising" className='img'/> */}
                  <img src={tricycle2dSmallest} alt="tricycle advertising price" className='img'/>
                </div>                
                </div>
              </div>

              <div className="model-description align-description">
                <h2>
                  Tricycle Branding Services
                  <br />
                  <small>Ride Into the Spotlight!</small>
                </h2>
                <p className="p">
                  It is not just cost effective but also very popular BTL form of advertising.
                  In Bangalore cities, this technique helps the business meet their target audience at eye level.
                  Tricycle advertising is implemented 7 hours in a day, and reaches upto 3-5 kms of its
                  surroundings.
                  <br />
                  Discover the impact of our tricycle branding services - click to view 
                  <span
                    onClick={() => {
                      setTricycleBrand(true);
                    }}
                    className="blogLink"
                  >
                    live preview
                  </span>
                    !!
                </p>
                <p className="p">
                  Considering
                  <em>
                    <i>
                      <b>tricycle branding</b>
                    </i>
                  </em>{" "}
                  for your product advertisement? Click below to learn more or to get in touch with our team!
                  <br />
                  <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
                  />
                </p>
              </div>
              <div>
              <Model
                isOpen={tricycleBrand}
                className="live-pic-container live-modal"
              >
                <i
                  className="fa fa-times close-btn"
                  aria-hidden="true"
                  onClick={() => setTricycleBrand(false)}
                ></i>
                <h2 className="text-center">Sample Work</h2>
                <div className="product">
                  <div className="service-pics live-pics">
                    <div className="pics">
                      <img src={tricycel01} alt="tricycle advertising price" />
                    </div>
                    <div className="pics">
                      <img src={tricycel02} alt="tricycle advertising in bangalore" />
                    </div>
                    <div className="pics">
                      <img src={tricycel03} alt="tricycle advertising agency" />
                    </div>
                    <div className="pics">
                      <img src={tricycel04} alt="tricycle advertising" />
                    </div>
                    <div className="pics">
                      <img src={tricycel05} alt="tricycle branding in bangalore" />
                    </div>
                    <div className="pics">
                      <img src={tricycel06} alt="tricycle branding" />
                    </div>
                    
                  </div>
                </div>
              </Model>
            </div>
            </div>

            <div className="ad-container container-position ad-containerGrey">
              <div className="left-ad-model">
              <div className="ad-model">
                <div className='item'>
                  <img src={rwa2dLarge} alt="rwa activities" className='img'/>
                  <img src={rwa2dBig} alt="rwa branding" className='img'/>
                   {/* <img src={rwa2dSmall} alt="resident welfare association activities" className='img'/> */}
                  <img src={rwa2dSmallest} alt="rwa gate branding" className='img'/>
                </div>                
                </div>
              </div>

              <div className="model-description">
                <h2>
                  RWA & Society Branding Services
                  <br />
                  <small>Connect, Collaborate, Thrive!</small>
                </h2>
                <p className="p">
                 Personal communication helps boost our client's sales and make sure more people know about
                 their products through effective advertising. This benefits to reach the audience within
                 their comfort zone and at the same time increases our client's product awareness and sales.
                  <br />
                  Discover the impact of our rwa & society branding services - click to view 
                  <span
                    onClick={() => {
                      setRWABrand(true);
                    }}
                    className="blogLink"
                  >
                    live preview
                  </span>
                    !!
                </p>
                <p className="p">
                  Considering
                  <em>
                    <i>
                      <b>rwa & society branding</b>
                    </i>
                  </em>{" "}
                  for your product advertisement? Click below to learn more or to get in touch with our team!
                  <br />
                  <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
                  />
                </p>
              </div>
              <div>
              <Model
                isOpen={rwaBrand}
                className="live-pic-container live-modal"
              >
                <i
                  className="fa fa-times close-btn"
                  aria-hidden="true"
                  onClick={() => setRWABrand(false)}
                ></i>
                <h2 className="text-center">Sample Work</h2>
                <div className="product">
                  <div className="service-pics live-pics">
                    <div className="pics">
                      <img src={rwa01} alt="rwa 2024" />
                    </div>
                    <div className="pics">
                      <img src={rwa02} alt="rwa gate branding" />
                    </div>
                    <div className="pics">
                      <img src={rwa03} alt="resident welfare association activities" />
                    </div>
                    <div className="pics">
                      <img src={rwa04} alt="rwa branding" />
                    </div>
                    <div className="pics">
                      <img src={rwa05} alt="rwa website" />
                    </div>
                    <div className="pics">
                      <img src={rwa06} alt="rwa" />
                    </div>
                    
                  </div>
                </div>
              </Model>
            </div>
            </div>

            <div className="ad-container container-position ad-containerWhite">
              <div className="right-ad-model">
              <div className="ad-model">
                <div className='item'>
                  <img src={window2dLarge} alt="inshop branding" className='img'/>
                  <img src={window2dBig} alt="branding in mall" className='img'/>
                   {/* <img src={window2dSmall} alt="window display branding" className='img'/> */}
                  <img src={window2dSmallest} alt="mall branding" className='img'/>
                </div>                
                </div>
              </div>

              <div className="model-description align-description">
                <h2>
                  Window Display Branding Services
                  <br />
                  <small>From Vision to Reality!</small>
                </h2>
                <p className="p">
                  Visually stunning and strategically designed window displays in boosting sales and
                  elevating brand visibility. Our expert team specializes in crafting captivating displays
                  that not only showcase your products but also tell a compelling story about your brand.
                  <br />
                  Discover the impact of our window display branding services - click to view 
                  <span
                    onClick={() => {
                      setWindowBrand(true);
                    }}
                    className="blogLink"
                  >
                    live preview
                  </span>
                    !!
                </p>
                <p className="p">
                  Considering
                  <em>
                    <i>
                      <b>window display branding</b>
                    </i>
                  </em>{" "}
                  for your product advertisement? Click below to learn more or to get in touch with our team!
                  <br />
                  <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
                  />
                </p>
              </div>
              <div>
              <Model
                isOpen={windowBrand}
                className="live-pic-container live-modal"
              >
                <i
                  className="fa fa-times close-btn"
                  aria-hidden="true"
                  onClick={() => setWindowBrand(false)}
                ></i>
                <h2 className="text-center">Sample Work</h2>
                <div className="product">
                  <div className="service-pics live-pics">
                    <div className="pics">
                      <img src={window01} alt="inshop branding" />
                    </div>
                    <div className="pics">
                      <img src={window02} alt="mall branding" />
                    </div>
                    <div className="pics">
                      <img src={window03} alt="brand shopping mall" />
                    </div>
                    <div className="pics">
                      <img src={window04} alt="branding mall" />
                    </div>
                    <div className="pics">
                      <img src={window05} alt="window display branding" />
                    </div>
                    <div className="pics">
                      <img src={window06} alt="branding in mall" />
                    </div>
                    
                  </div>
                </div>
              </Model>
            </div>
            </div>


            <div className="ad-container container-position ad-containerGrey">
              <div className="left-ad-model">
              <div className="ad-model">
                <div className='item'>
                  <img src={demo2dLarge} alt="brand demo" className='img'/>
                  <img src={demo2dBig} alt="demonstration services" className='img'/>
                   {/* <img src={demo2dSmall} alt="cds demonstration" className='img'/> */}
                  <img src={demo2dSmallest} alt="cds demonstration services" className='img'/>
                </div>                
                </div>
              </div>

              <div className="model-description">
                <h2>
                  Branding & Demonstration Services
                  <br />
                  <small>Stand Out, Shine Bright</small>
                </h2>
                <p className="p">
                  It creates a powerful synergy that amplifies the impact and effectiveness of marketing efforts.
                  <br />
                  Discover the impact of our branding services - click to view 
                  <span
                    onClick={() => {
                      setDemoBrand(true);
                    }}
                    className="blogLink"
                  >
                    live preview
                  </span>
                    !!
                </p>
                <p className="p">
                  Considering
                  <em>
                    <i>
                      <b>branding services</b>
                    </i>
                  </em>{" "}
                  for your product advertisement? Click below to learn more or to get in touch with our team!
                  <br />
                  <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
                  />
                </p>
              </div>
              <div>
              <Model
                isOpen={demoBrand}
                className="live-pic-container live-modal"
              >
                <i
                  className="fa fa-times close-btn"
                  aria-hidden="true"
                  onClick={() => setDemoBrand(false)}
                ></i>
                <h2 className="text-center">Sample Work</h2>
                <div className="product">
                  <div className="service-pics live-pics">
                    <div className="pics">
                      <img src={demo01} alt="brand demo" />
                    </div>
                    <div className="pics">
                      <img src={demo02} alt="demonstration services" />
                    </div>
                    <div className="pics">
                      <img src={demo03} alt="cds demonstration" />
                    </div>
                    <div className="pics">
                      <img src={demo04} alt="cds demonstration services" />
                    </div>
                    <div className="pics">
                      <img src={demo05} alt="customer demonstration services" />
                    </div>
                    <div className="pics">
                      <img src={demo06} alt="demo clothing brand" />
                    </div>
                    
                  </div>
                </div>
              </Model>
            </div>
            </div>

            <div className="ad-container container-position ad-containerWhite">
              <div className="right-ad-model">
              <div className="ad-model">
                <div className='item'>
                  <img src={lookwalker2dLarge} alt="look walker" className='img'/>
                  <img src={lookwalker2dBig} alt="lookwalker activity" className='img'/>
                   {/* <img src={lookwalker2dSmall} alt="lookwalker advertising" className='img'/> */}
                  <img src={lookwalker2dSmallest} alt="lookwalker board" className='img'/>
                </div>                
                </div>
              </div>

              <div className="model-description align-description">
                <h2>
                  Lookwalker Branding
                  <br />
                  <small>Popular strategy to advertise brands.</small>
                </h2>
                <p className="p">
                Look Walker Activity is a unique & cost effective outdoor advertising which
                increases visibility in traffic areas & engagement with pedestrians.
                Our well planned Lookwalker Activities shall fetch you Local Partnerships and Sponsorships.
                  <br />
                  {/* <Link to="/Contact" className="blogLink">
                    <span>Click Me </span>
                  </Link> */}
                  <span
                    onClick={() => {
                      setLookwalkerBrand(true);
                    }}
                    className="blogLink"
                  >
                    Live Preview
                  </span>
                  to know different types of lookwalker branding and our sample work.
                </p>
                <p className="p">
                  Want to use{" "}
                  <em>
                    <i>
                      <b>lookwalker branding</b>
                    </i>
                  </em>{" "}
                  for your product advertisement?
                  <br />
                  <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
                  />
                </p>
              </div>
              <div>
              <Model
                isOpen={lookwalkerBrand}
                className="live-pic-container live-modal"
              >
                <i
                  className="fa fa-times close-btn"
                  aria-hidden="true"
                  onClick={() => setLookwalkerBrand(false)}
                ></i>
                <h2 className="text-center">Sample Work</h2>
                <div className="product">
                  <div className="service-pics live-pics">
                    <div className="pics">
                      <img src={lookwalker01} alt="lookwalker led" />
                    </div>
                    <div className="pics">
                      <img src={lookwalker02} alt="lookwalker branding" />
                    </div>
                    <div className="pics">
                      <img src={lookwalker03} alt="lookwalker price" />
                    </div>
                    <div className="pics">
                      <img src={lookwalker04} alt="lookwalker" />
                    </div>
                    <div className="pics">
                      <img src={lookwalker05} alt="lookwalker advertising" />
                    </div>
                    <div className="pics">
                      <img src= {lookwalker06} alt="lookwalker activity" />
                    </div>
                    
                  </div>
                </div>
              </Model>
            </div>
            </div>

            <div className="ad-container container-position ad-containerGrey">
              <div className="left-ad-model">
              <div className="ad-model">
                <div className='item'>
                  <img src={seasonal2dLarge} alt="festive marketing" className='img'/>
                  <img src={seasonal2dBig} alt="marketing for festivals" className='img'/>
                   {/* <img src={seasonal2dSmall} alt="festival marketing in bangalore" className='img'/> */}
                  <img src={seasonal2dSmallest} alt="festive season advertising" className='img'/>
                </div>                
                </div>
              </div>

              <div className="model-description">
                <h2>
                  Seasonal & Festive Branding
                  <br />
                  <small>Make the most out of festives!!</small>
                </h2>
                <p className="p">
                Chose themes that direct your campaign's branding
                and imagery & fits well with your company product and values.

                  <br />
                  {/* <Link to="/Contact" className="blogLink">
                    <span>Click Me </span>
                  </Link> */}
                  <span
                    onClick={() => {
                      setSeasonalBrand(true);
                    }}
                    className="blogLink"
                  >
                    Live Preview
                  </span>
                  to know different types of seasonal branding and our sample work.
                </p>
                <p className="p">
                  Want to use{" "}
                  <em>
                    <i>
                      <b>seasonal branding</b>
                    </i>
                  </em>{" "}
                  for your product advertisement?
                  <br />
                  <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
                  />
                </p>
              </div>
              <div>
              <Model
                isOpen={seasonalBrand}
                className="live-pic-container live-modal"
              >
                <i
                  className="fa fa-times close-btn"
                  aria-hidden="true"
                  onClick={() => setSeasonalBrand(false)}
                ></i>
                <h2 className="text-center">Sample Work</h2>
                <div className="product">
                  <div className="service-pics live-pics">
                    <div className="pics">
                      <img src={seasonal01} alt="festive marketing" />
                    </div>
                    <div className="pics">
                      <img src={seasonal02} alt="marketing for festivals" />
                    </div>
                    <div className="pics">
                      <img src={seasonal03} alt="festival marketing in bangalore" />
                    </div>
                    <div className="pics">
                      <img src={seasonal04} alt="festive season advertising" />
                    </div>
                    <div className="pics">
                      <img src={seasonal05} alt="festive branding" />
                    </div>
                    <div className="pics">
                      <img src= {seasonal06} alt="festive season marketing" />
                    </div>
                    
                  </div>
                </div>
              </Model>
            </div>
            </div>

            <div className="ad-container container-position ad-containerWhite">
              <div className="right-ad-model">
              <div className="ad-model">
                <div className='item'>
                  <img src={metro2dLarge} alt="metro branding" className='img'/>
                  <img src={metro2dBig} alt="metro advertisement" className='img'/>
                   {/* <img src={metro2dSmall} alt="metro marketing" className='img'/> */}
                  <img src={metro2dSmallest} alt="metro ads" className='img'/>
                </div>                
                </div>
              </div>

              <div className="model-description align-description">
                <h2>
                  Metro Branding
                  <br />
                  <small>Popular strategy to advertise brands.</small>
                </h2>
                <p className="p">
                Metros are hubs of activity all the time, so your advertisements could spark interest
                and conversation among commuters. Ensuring that a wide spectrum of demographics
                hear your message, maximizing brand exposure and creating a lasting impact
                  <br />
                  {/* <Link to="/Contact" className="blogLink">
                    <span>Click Me </span>
                  </Link> */}
                  <span
                    onClick={() => {
                      setMetroBrand(true);
                    }}
                    className="blogLink"
                  >
                    Live preview
                  </span>
                    to know different types of metro branding and our sample work.
                </p>
                <p className="p">
                  Want to use{" "}
                  <em>
                    <i>
                      <b>metro branding</b>
                    </i>
                  </em>{" "}
                  for your product advertisement?
                  <br />
                  <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
                  />
                </p>
              </div>
              <div>
              <Model
                isOpen={metroBrand}
                className="live-pic-container live-modal"
              >
                <i
                  className="fa fa-times close-btn"
                  aria-hidden="true"
                  onClick={() => setMetroBrand(false)}
                ></i>
                <h2 className="text-center">Sample Work</h2>
                <div className="product">
                  <div className="service-pics live-pics">
                    {/*
                    <div className="pics">
                      <img src= "" alt="metro advertising cost" />
                    </div>
                    <div className="pics">
                      <img src= "" alt="metro ads" />
                    </div>
                    <div className="pics">
                      <img src= "" alt="metro pillar advertising" />
                    </div>
                    <div className="pics">
                      <img src="" alt="metro marketing" />
                    </div>
                    <div className="pics">
                      <img src= "" alt="metro advertisement" />
                    </div>
                    <div className="pics">
                      <img src= "" alt="metro branding" />
                    </div>
                    */}
                  </div>
                </div>
                <div>
                      <h5 className="text-center">
                        <i><b>
                        Stay Tuned !!
                        </b></i> <br></br>
                        <i><b>
                        Every day, we challange ourselves by taking on new tasks and endevours. More images COMING SOON.
                        </b></i>
                      </h5>  
                </div>

                <div className='center'>
                <img src= {comingsoon} alt="metro branding" />
                </div>    

              </Model>
            </div>
            </div>

            <div className="ad-container container-position ad-containerGrey">
              <div className="left-ad-model">
              <div className="ad-model">
                <div className='item'>
                  <img src={bus2dLarge} alt="bus advertisement" className='img'/>
                  <img src={bus2dBig} alt="bus marketing" className='img'/>
                   {/* <img src={bus2dSmall} alt="bus ads" className='img'/> */}
                  <img src={bus2dSmallest} alt="ads on buses" className='img'/>
                </div>                
                </div>
              </div>

              <div className="model-description">
                <h2>
                  Bus Branding
                  <br />
                  <small>Popular strategy to advertise brands.</small>
                </h2>
                <p className="p">
                This low-cost branding strategy is a great option to build the brand and interact with
              prospective clients because of its wide audience reach, excellent visibility, mobility,
              and consistent presence.
                  <br />
                  {/* <Link to="/Contact" className="blogLink">
                    <span>Click Me </span>
                  </Link> */}
                  <span
                    onClick={() => {
                      setBusBrand(true);
                    }}
                    className="blogLink"
                  >
                    Live Preview
                  </span>
                  to know different types of bus branding and our sample work.
                </p>
                <p className="p">
                  Want to use{" "}
                  <em>
                    <i>
                      <b>bus branding</b>
                    </i>
                  </em>{" "}
                  for your product advertisement?
                  <br />
                  <input
                    type="button"
                    value="Enquiry"
                    className="button"
                    onClick={() => setEnquiryShow(true)}
                  />
                </p>
              </div>
              <div>
              <Model
                isOpen={busBrand}
                className="live-pic-container live-modal"
              >
                <i
                  className="fa fa-times close-btn"
                  aria-hidden="true"
                  onClick={() => setBusBrand(false)}
                ></i>
                <h2 className="text-center">Sample Work</h2>
                <div className="product">
                  <div className="service-pics live-pics">
                    <div className="pics">
                      <img src= {bus01} alt="bus back panel advertising" />
                    </div>
                    {/* 
                    <div className="pics">
                      <img src= "" alt="bus signage" />
                    </div>
                    <div className="pics">
                      <img src= "" alt="marketing bus" />
                    </div>
                    <div className="pics">
                      <img src= "" alt="ads on buses" />
                    </div>
                    <div className="pics">
                      <img src= ""  alt="transit advertising" />
                    </div>
                    <div className="pics">
                      <img src= "" alt="bus ads" />
                    </div>
                    */}
                  </div>
                </div>

                <div>
                      <h6 className="text-center">
                        <i><b>
                        Every day, we challange ourselves by taking on new tasks and endevours. More images COMING SOON, Stay Tuned !!
                        </b></i>
                      </h6>
                </div>

              </Model>
            </div>

          </div>
          </div>  

          <div>         
          <FAQ/>
        </div>

        </div>
       
    </>
  )
}

export default AdHome
