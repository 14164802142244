import React, {useState, useEffect} from "react";
import "../css/Contact.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from "./Navbar";
import Swal from "sweetalert2";
import { redBorder } from '../functions/Validator';
import { changeBorder } from '../functions/Validator';
import ReactGA from "react-ga";

import SEO from '../components/SEO';
 
AOS.init();
const Contact = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  },[]);

    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [message, setMessage] = useState('');

  const contactSubmitHandler = async (ev) => {
    var status = 0;
    ev.preventDefault();

    if (status==0){
      const isTextValid = /^[A-Za-z\s]{4,}$/.test(name);
      const isMailValid = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/.test(mail);
      const isMessageValid = /^[a-zA-Z\s0-9.-]+$/.test(message);
      
      // Check if the textbox has valid name or not
      if (!isTextValid){     
      Swal.fire({
          title: "Validation!",
          text: "Name Must have minimum 4 letters. You cannot use numbers or any other special characters with name.",//"Error Ocurred. Try After Sometime",
          icon: "warning",
          customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
      })               
      }

      // check if the mail has valid mail address or not
      if(!isMailValid){
        Swal.fire({
          title: "Validation!",
          text: "Mail format is wrong. For Example: name@gmail.com",//"Error Ocurred. Try After Sometime",
          icon: "warning",
          customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
      })                           
      }       

      // check if the requirement details box is valid or not
      if(!isMessageValid){
        Swal.fire({
          title: "Validation!",
          text: "You cannot use special characters in requirement details.",//"Error Ocurred. Try After Sometime",
          icon: "warning",
          customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
      })
      }
      
      if(isTextValid && isMailValid && isMessageValid){
        status=1;
      }
    }

    if (status==1){
      try{
        const response = await fetch('https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/postcontact', {
          method:'POST',
          body: JSON.stringify({name,mail,message}),
          headers:{
            "Content-Type": "application/json"
          },
        });
        if(response.status === 200){
          Swal.fire({
            title: "Contact",
            text: "Enquiry Submitted!",
            icon: "success",
            customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
            }
          });
          setName('');
          setMail('');
          setMessage('');          
        }else{
          Swal.fire({
            title: "Contact",
            text: response.status,//"Oops! Enquiry Failed.",
            icon: "warning",
            customClass: {
              confirmButton: 'custom-swal-button' //Apply custom class to the confirm button
            }
          });
        }

      }catch(e){
        Swal.fire({
          title: "Contact!",
          text: e,//"Error Ocurred. Try After Sometime",
          icon: "error",
          customClass: {
            confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
        });
      }
    }
  }

  return (
    <>

<SEO
    title="Best Marketing and Advertising Company in Bangalore"
    description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
    keywords="Good design and feasible branding agency,
    best marketing agency,
    best marketing agency near me,
    best marketing agency in bangalore,
    best marketing companies in bangalore,
    Best marketing agency instagram accounts,
    best offline marketing companies,
    best offline marketing companies in bangalore,
    best offline marketing companies near me,
    best marketing and advertising companies"
  />
      <Navbar/>
      
      {/* framer-motion is not required for this page */}
      <section className="contactwrapper">
        <div className="clearfix">
          <div className="float-left" data-aos='fade-right' data-aos-delay='50' data-aos-duration='1100'>
            {/* <!-- <img src="img/connet/connect1.png" className="img-fluid d-block d-md-none wow slideInLeft connect1back"/> --> */}
           {/* <h1>
              You can contact us here.
            </h1> */}
            <p className="contact-details">

            <h5>Better Discussions, Better Decisions</h5>

            <a href="https://alvo.chat/4cbi" target="_blank" className="contact-details">
              <span className="icon">
                <i class="fa fa-user" aria-hidden="true"></i>
              </span>
              <span className="icon-name" data-tooltip="This is a tooltip" data-toggle="tooltip" data-placement="right" title="Click to chat on whats app">
                      SJ Ahmed
              </span>
              </a>
            </p>
            <p className="contact-details">
            <a href="tel:+918147688307">
              <span className="icon">
                <i className="fa fa-phone" aria-hidden="true"></i>
              </span>
              <span className="contact-details icon-name" data-toggle="tooltip" data-placement="right" title="Click to call">
                      +91 8147688307
              </span>
              </a>
            </p>

            <p className="contact-details">
            <a href="tel:+918904678362">
              <span className="icon">
                <i className="fa fa-phone" aria-hidden="true"></i>
              </span>
              <span className="contact-details icon-name" data-toggle="tooltip" data-placement="right" title="Click to call">
                      +91 8904678362
              </span>
              </a>
            </p>

            <p className="contact-details">
            <a href="tel:+919035300795">
              <span className="icon">
                <i className="fa fa-phone" aria-hidden="true"></i>
              </span>
              <span className="contact-details icon-name" data-toggle="tooltip" data-placement="right" title="Click to call">
                      +91 9035300795
              </span>
              </a>
            </p>

            <p className="contact-details">
              <span className="icon">
                <i className="fa fa-envelope" aria-hidden="true"></i>
              </span>
              <span>
                <a href="mailto:admin@sjdinnovative.com" className="contact-details icon-name"
                data-toggle="tooltip" data-placement="right" title="Click to mail us">
                  admin@sjdinnovative.com
                  </a>
              </span>
            </p>

            <p className="contact-details">
              <span className="icon">
                <i className="fa fa-map-marker" aria-hidden="true"></i>
              </span>
              <span className="align-top">
                  #113/1 Opp - Metro Pillar No 213,
                <br />
                  Magadi Main Road,
                <br />
                Bangalore, Karnataka 560023
                <br />

              </span>
            </p>

          </div>


          <div className="float-right" data-aos='fade-left' data-aos-delay='50' data-aos-duration='1100'>
           
            <div className="contactForm">
              <form action="" onSubmit={contactSubmitHandler}>
                <h2>Share your thoughts</h2>
                <div className="inputBox">
                  <input 
                    type="text" 
                    name="" 
                    id="name" 
                    required="required" 
                    value={name}
                    onChange={ev => setName(ev.target.value)}
                  />
                  <span>Full Name</span>
                </div>
                <div className="inputBox">
                  <input 
                    type="email" 
                    name="" 
                    id="mail" 
                    required="required"
                    vcalue={mail}
                    onChange={ev => setMail(ev.target.value)}
                  />
                  <span>Email</span>
                </div>
                <div className="inputBox">
                  <textarea 
                    rows="1" 
                    id="message"
                    required="true"
                    value={message}
                    onChange={ev => setMessage(ev.target.value)}
                  ></textarea>
                  <span>Type your message...</span>
                </div>
                <div className="inputBox">
                  <input type="submit" name="" value="Send" />
                </div>
              </form>
            </div>

          </div>

        </div>
      </section>

      <section className="googleMap" data-aos='fade-up' data-aos-delay='50' data-aos-duration='2000'>

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.938625835199!2d77.55862337381038!3d12.975777314789216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d44e5292b85%3A0xd5e78deb83088f!2sSJ%20Dariyath%20Innovative%20Creations!5e0!3m2!1sen!2sin!4v1705411415834!5m2!1sen!2sin" 
            width="100%" 
            height="350" 
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade">          
        </iframe>

      </section>
    </>
  );
};

export default Contact;
