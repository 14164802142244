import React from 'react'
import AdminPanel from './AdminPanel'
import axios from 'axios';
import {useEffect, useState} from "react";
import { Link, Navigate } from 'react-router-dom'
import "../css/QueryReceived.css"

import SEO from '../components/SEO';

//to fetch the users' queries

const AdvertisementQuery = () => {

  const [object, setObj] = useState([
    {
      title: "",
      description: "",
      url: "",
    },
  ]);

  const [advertisements,  setAdvertisements] = useState([])
  
  useEffect(() => {
  axios.get('https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/postadquery_admin')
  .then(advertisements => setAdvertisements(advertisements.data))
  .catch(err => console.log(err))
}, [])

  return (
    <>
   <SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="best btl marketing agency in bangalore, Top Advertising Agencies For Bus in Bangalore, Bus Advertising in India at Best Rates,
        RWA Activity Services at best price in bangalore, Top Quality LED Look Walker, List of Best Look Walker Companies in Bengaluru,
        Top In Shop Branding Services in Bangalore"
      />

        <AdminPanel/>
        <div className="admin-container">
            
            <h2>
            User Queries
            </h2>
        
            <div className="query-cont">
                <div className="card">
                 
                </div>
            </div>
            <div className='container'>
            <div className="row">
                    <div className="column">
                      <Link style={{color:"#D52591E6", background:"white"}} type="submit" name="" value="Advertisement Queries" to="/AdvertisementQuery">
                        Advertisement Queries
                      </Link>
                    </div>
                    <div className="column">
                      <Link style={{color:"white"}} type="submit" name="" value="Advertisement Queries" to="/ProductQuery">
                        Product Queries
                      </Link>
                    </div>
                    <div className="column">
                      <Link style={{color:"white"}} type="submit" name="" value="Advertisement Queries" to="/ServiceQuery">
                        Services Queries
                      </Link>
                    </div>
                  </div>
            <table id='users'> 
                <tr>
                  <thead>
                    <th>Name</th>
                    <th>Mail ID</th>
                    <th>Contact No.</th>
                    <th>Advertisement Type</th>
                    <th>Budget</th>
                    <th>Details</th>
                  </thead>
                  <tbody>
                      {
                        advertisements.map((advertisements) => {
                          return <tr>
                            <td>{advertisements.name}</td>
                            <td>{advertisements.mail}</td>
                            <td>{advertisements.phone}</td>
                            <td>{advertisements.adName}</td>
                            <td>{advertisements.budget}</td>
                            <td>{advertisements.details}</td>
                          </tr>
                        })
                      }
                  </tbody>
                </tr>
            </table>
            </div>
        </div>
        
    </>
  )
}

export default AdvertisementQuery
