import React, { useMemo, useState, useRef } from 'react';
import '../css/Blogposter.css';
import AdminPanel from './AdminPanel';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import Swal from "sweetalert2";
import { Link, Navigate } from 'react-router-dom'
// import { createEditor } from 'slate';
// import { Slate, Editable, withReact } from 'slate-react';
// import JoditEditor from 'jodit-react';
// import HTMLReactParser from 'html-react-parser';

const  modules = {
    toolbar: [
      [{ 'header': [2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  };

  const  formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

function Blogposter() {
      const [title,setTitle]= useState('');
      const [summary, setSummary] = useState('');
      const [content, setContent] = useState('');
      const [cover, setFiles] = useState('');
      const [redirect, setRedirect] = useState(false);
      // const editor = useMemo(() => withReact(createEditor()), []);
      // const [value, setValue] = useState([{ type: 'paragraph', children: [{ text: '' }] }]);

      const editor = useRef(null);

      // const handleChange = (newValue) => {
      //     setValue(newValue);
      // };

      const createNewPost = async (e) =>{

  try {     
        const data = new FormData();
        data.set('title', title);
        data.set('summary', summary);
        data.set('content', content);
        data.set('file', cover);
        e.preventDefault();
        const response = await fetch('https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/postblogs',{
          method:'POST',
          body: JSON.stringify({title,summary,content,cover}),
          headers:{
            "Content-Type": "application/json"
          },
        });

        if (response.ok) {
          setRedirect(true);
          Swal.fire({
              title: "Blog",
              text: "Blog Posted!",
              icon: "success",
              customClass: {
                  confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
              }
          });
      } else {
          // Handle error response
          // For example, display an error message
          Swal.fire({
              title: "Error",
              text: "Failed to post blog",
              icon: "error",
              customClass: {
                  confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
              }
          });
      }
  } catch (error) {
      // Handle any other errors that occur during the request
      // console.error('Error posting offer:', error);
      console.log(error)
      Swal.fire({
          title: "Error",
          text: "Failed to post blog. Please try again later.",
          icon: "error",
          customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
      });
  }
};

  return (
    <>
         <AdminPanel/>
         <div className="admin-container">
        <h2>Post Your Blog</h2>
        <div className='createuser'><Link to="/BlogDisplay">Blogs</Link></div>
        <form action="" onSubmit={createNewPost}>
        <div className='input-label-box'>
            <label htmlFor="title">
              Title    
              <small><i>(Give Only 50-60 characters)</i></small>
            </label>
            <input 
                className="input" 
                type="title" 
                name="title" 
                id="title" 
                placeholder='Title'
                value={title}
                onChange={(e)=>{setTitle(e.target.value)}}
            />
        </div>        
     
     <div className='input-label-box'>
            <label htmlFor="title">
              Summary
              <small><i>(Give Only 150-160 characters)</i></small>
            </label>
            <input 
                className="input" 
                type="summary" 
                name="summary" 
                id="title" 
                placeholder='Summary'
                value={summary}
                onChange={(e) =>{setSummary(e.target.value)}}
            />
        </div>

        <div className='input-label-box'>
            <label htmlFor="title">
              Copy paste the Cloudinary Image URL 
              <small><i>(4:3 image preferably)</i></small>
            </label>
            <input 
                className="input" 
                type="cover" 
                placeholder='Image URL'
                value={cover}
                onChange={(e) =>{setFiles(e.target.value)}}
                required="true"
            />
        </div>

        <div className='input-label-box'>
            <label htmlFor="content">
              Content 
              <small><i>(Edit the blog in wordpad then copy-paste here. Give Only 1000-1600 words)</i></small>
            </label>
            <ReactQuill 
                className='bg-white'
                placeholder="Write blog..." 
                value={content}
                onChange={newValue =>{setContent(newValue)}}
            />
             {/* <Slate editor={editor} value={value} onChange={handleChange}>
              <Editable />
            </Slate> */}
            {/* <JoditEditor
              ref={editor}
              value={content}
              placeholder="Write blog..." 
              onChange={newValue =>{HTMLReactParser(setContent(newValue))}}
            />
            <div>{HTMLReactParser(content)}</div> */}
        </div>        
        <div className='input-label-box'>
            <input className="sub-but float-center" type="submit" value="Post" />
        </div>
    </form>
    </div>
    </>
  )
}

export default Blogposter
