import React, { useState } from 'react';
import '../css/FAQ.css'; // Import the CSS file
import "../css/Home.css";

const FAQ = () => {
  const faqs = [
    {
      question: "Do you provide installation services for events?",
      answer: "Yes, our team can handle the complete setup and takedown of all branding materials."
    },
    {
      question: "How far in advance should we contact you for event branding?",
      answer: "We recommend contacting us at least +++ days before the event to ensure timely delivery."
    },
    {
      question: "Can you assist with themed decorations for festivals and fairs?",
      answer: "Absolutely, we can design and produce themed decorations to enhance the festive atmosphere."
    },
    {
      question: "Are there any restrictions on branding materials for malls?",
      answer: "Restrictions vary by mall; we will coordinate with mall management to ensure compliance."
    },
    {
      question: "Do you offer branding solutions for product launches?",
      answer: "Yes, we specialize in creating impactful branding for product launches, including demo stations and promotional materials."
    },
    // Add more FAQs as needed
  ];



  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
    
    <div className="heading">
              <h2>Frequently Asked Questions (FAQ)</h2>
    </div>


      <div>
        {faqs.map((faq, index) => (
          <div className="faq-item" key={index}>
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              {faq.question}
            </div>
            <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
