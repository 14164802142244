import React from 'react';
import '../css/Testimonials.css';
import { FaStar } from 'react-icons/fa'; 

const Testimonials = ({ testimonials }) => {

  const renderStars = (rating) => {
    return [...Array(5)].map((star, index) => {
      return (
        <FaStar
          key={index}
          color={index < rating ? '#ffc107' : '#e4e5e9'} // Highlight stars based on rating
        />
      );
    });
  };


  return (
    <div className="testimonials">
      <h2 className="text-center"><b>Feedback That Keep Us Going!!</b></h2>
      <h6 className="text-center">We are not all confetti and glitter!! We accept all kinds of feedbacks that help us grow.</h6>
      <div className="testimonial-cards">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <img src={testimonial.image} alt={`${testimonial.name}'s photo`} className="client-photo" /> 
            <br></br>
            <div className="rating">{renderStars(testimonial.rating)}</div>
            <br></br>
            <p className="testimonial-text">{testimonial.text}</p>
            <p className="testimonial-client">- {testimonial.client}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
