import React from 'react'
import HeroElement from '../video/heroo.jpg';

import SEO from '../components/SEO';

const Hero = () => {
  return (
    <>

<SEO
    title="Best Marketing and Advertising Company in Bangalore"
    description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
    keywords="best marketing and advertising companies in bangalore,
    best marketing and advertising companies in india,
    best advertising agencies in bangalore,
    top 10 advertising agencies in bangalore,
    top 10 advertising agencies in bangalore,
    outdoor advertising agencies in bangalore,
    best advertising company in india,
    best advertising company in bangalore,
    best advertising company"
  />

         <div className="DariyathVideo bg-white">
            <img src={HeroElement}></img>
           {/* //todo: give an alternative image for devices that does not support video */}
            {/* <img src='/images/Digital standee 2.jpg' alt="Fallback Image" /> */}
         </div>
    </>
  )
}

export default Hero
