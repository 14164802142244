import React from 'react';
import '../css/Accomplishments.css';

const Accomplishments = ({ accomplishments }) => {
  return (
    <div className="accomplishments">
      <div className="accomplishments-cards">
        {accomplishments.map((accomplishments, index) => (
          <div className="accomplishments-card" key={index}>
            <p className="accomplishments-text">{accomplishments.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accomplishments;
