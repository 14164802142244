import React, { useMemo, useState, useRef }  from 'react'
import AdminPanel from './AdminPanel'

import '../css/Blogposter.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import Swal from "sweetalert2";
import { Link, Navigate } from 'react-router-dom'

import SEO from '../components/SEO';

    const  modules = {
        toolbar: [
          [{ 'header': [2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ['clean']
        ],
      };
    
      const  formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
      ];


    function OfferPoster() {
          const [title,setTitle]= useState('');
          const [description, setDescription] = useState('');
          const [cover, setFiles] = useState('');

          const [fileInputElement, setFileInputElement] = useState(null);

          const [redirect, setRedirect] = useState(false);
          // const editor = useMemo(() => withReact(createEditor()), []);
          // const [value, setValue] = useState([{ type: 'paragraph', children: [{ text: '' }] }]);
    
          const editor = useRef(null);
    
          // const handleChange = (newValue) => {
          //     setValue(newValue);
          // };
    
          const createNewOffer = async (e) =>{

      try {
        const data = new FormData();
        //data.set('title', title);
        //data.set('description', description);
        //data.set('file', files[0]);

        data.append('title', title);
        data.append('description', description);
        data.append('file', cover);
        
        e.preventDefault();
        const response = await fetch('https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/postoffers', {
            method:'POST',
            body: JSON.stringify({title,description,cover}),
            headers:{
              "Content-Type": "application/json"
            },
          });

        if (response.ok) {
            setRedirect(true);
            Swal.fire({
                title: "Poster",
                text: "Offer Posted!",
                icon: "success",
                customClass: {
                    confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
                }
            });
        } else {
            // Handle error response
            // For example, display an error message
            Swal.fire({
                title: "Error",
                text: "Failed to post offer",
                icon: "error",
                customClass: {
                    confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
                }
            });
        }
    } catch (error) {
        // Handle any other errors that occur during the request
        // console.error('Error posting offer:', error);
        console.log(error)
        Swal.fire({
            title: "Error",
            text: "Failed to post offer. Please try again later.",
            icon: "error",
            customClass: {
                confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
            }
        });
    }
};
    
          {/* if(redirect){
            return <Navigate to={"/OfferPoster"}/>
          } */}

          return (
            <>
<SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="Best quality auto branding company near me,
        Best quality auto branding company in bangalore,
        Best quality auto top branding company near me,
        Best quality auto hood branding company near me,
        Best quality mobile van branding company near me,
        Best quality mobile van branding company in bangalore,
        Best quality cab branding company near me,
        Best quality cab branding company near me,
        Best quality bus branding company near me,
        Best quality bus branding company near me,
        "
      />
      
                 <AdminPanel/>
                 <div className="admin-container">
                <h2>Post New Offer</h2>
                <div className='createuser'><Link to="/OfferDisplay">Offers</Link></div>
                <form action="" onSubmit={createNewOffer}>
                <div className='input-label-box'>
                    <label htmlFor="title">
                    Enter Offer Title    
                      <small><i>(Give Only two words max)</i></small>
                    </label>
                    <input 
                        className="input" 
                        type="title" 
                        name="title" 
                        id="title" 
                        placeholder='Offer Title'
                        value={title}
                        onChange={(e)=>{setTitle(e.target.value)}}
                    />
                </div>        
             
             <div className='input-label-box'>
                    <label htmlFor="title">
                      Enter Offer Description
                      <small><i>(Give Only two lines max)</i></small>
                    </label>
                    <textarea 
                        className="input" 
                        type="summary" 
                        name="title" 
                        id="title" 
                        placeholder='Offer Description'
                        value={description}
                        onChange={(e) =>{setDescription(e.target.value)}}
                    />
                </div>

            <div className='input-label-box'>
            <label htmlFor="title">
              Copy paste the Cloudinary Image URL
              <small><i>(1:1 image preferably)</i></small>
            </label>
            <input 
                className="input" 
                type="cover" 
                placeholder='Image URL'
                value={cover}
                onChange={(e) =>{setFiles(e.target.value)}}
                required="true"
            />
          </div>
                <div className='input-label-box'>
                    <input className="sub-but float-center" type="submit" value="Post" />
                </div>
            </form>
            </div>
            </>
          )
        }

export default OfferPoster