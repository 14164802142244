import React from 'react'
import AdminPanel from './AdminPanel'
import axios from 'axios';
import {useEffect, useState} from "react";
import { Link, Navigate } from 'react-router-dom'
import "../css/QueryReceived.css"

import SEO from '../components/SEO';

//to fetch the users' queries

const FeedbackReceived = () => {

  const [object, setObj] = useState([
    {
      title: "",
      description: "",
      url: "",
    },
  ]);

  const [contact,  setContact] = useState([])
  
  useEffect(() => {
  axios.get('https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/getcontact')
  .then(contact => setContact(contact.data))
  .catch(err => console.log(err))
}, [])

  return (
    <>

<SEO
    title="Best Marketing and Advertising Company in Bangalore"
    description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
    keywords="best marketing and advertising companies in bangalore,
    best marketing and advertising companies in india,
    best advertising agencies in bangalore,
    top 10 advertising agencies in bangalore,
    top 10 advertising agencies in bangalore,
    outdoor advertising agencies in bangalore,
    best advertising company in india,
    best advertising company in bangalore,
    best advertising company"
  />

        <AdminPanel/>
        <div className="admin-container">
            
            <h2>
              User Feedback
            </h2>
        
            <div className="query-cont">
                <div className="card">
                 
                </div>
            </div>
            <div className='container'>
            <table id='users'> 
                <tr>
                  <thead>
                    <th>Name</th>
                    <th>Mail ID</th>
                    <th>Message</th>
                  </thead>
                  <tbody>
                      {
                        contact.map((contact) => {
                          return <tr>
                            <td>{contact.name}</td>
                            <td>{contact.mail}</td>
                            <td>{contact.message}</td>
                          </tr>
                        })
                      }
                  </tbody>
                </tr>
            </table>

            </div>
        </div> 
    </>
  )
}

export default FeedbackReceived
