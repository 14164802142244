import React from 'react'
import AdminPanel from './AdminPanel'
import axios from 'axios';
import {useEffect, useState} from "react";
import { Link, Navigate } from 'react-router-dom'
import "../css/QueryReceived.css"

import SEO from '../components/SEO';

//to fetch the users' queries

const QueryReceived = () => {

  const [object, setObj] = useState([
    {
      title: "",
      description: "",
      url: "",
    },
  ]);

  return (
    <>
       <SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="Best quality auto branding company near me,
        Best quality auto branding company in bangalore,
        Best quality auto top branding company near me,
        Best quality auto hood branding company near me,
        Best quality tricycle branding company near me,
        Best quality tricycle branding company in bangalore,
        Best quality lookwalker activity company near me,
        Best quality lookwalker branding company near me,
        Best quality metro branding company near me,
        Best quality metro branding company near me"
      />	  
      
        <AdminPanel/>
        <div className="admin-container">
            
            <h2>
              User Queries
            </h2>
        
            <div className="query-cont">
                <div className="card">
                 
                </div>
            </div>
            <div className='container'>
            <div className="row">
                    <div className="column">
                      <Link style={{color:"white"}} type="submit" name="" value="Advertisement Queries" to="/AdvertisementQuery">
                        Advertisement Queries
                      </Link>
                    </div>
                    <div className="column">
                      <Link style={{color:"white"}} type="submit" name="" value="Advertisement Queries" to="/ProductQuery">
                        Product Queries
                      </Link>
                    </div>
                    <div className="column">
                      <Link style={{color:"white"}} type="submit" name="" value="Advertisement Queries" to="/ServiceQuery">
                        Services Queries
                      </Link>
                    </div>
                  </div>
            </div>
        </div> 
    </>
  )
}

export default QueryReceived
