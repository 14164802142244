import React, { useState } from 'react';
import '../css/FAQ.css'; // Import the CSS file

const FAQ = () => {
  const faqs = [
    {
      question: "Do you offer customized solutions?",
      answer: "Yes, we tailor our services to meet the unique needs of each client. Our team works closely with you to develop solutions that best fit your requirements."
    },
    {
      question: "How can I contact customer support?",
      answer: "You can contact us by emailing admin@sjdinnovative.com or calling 8147688307/8904678362. Or you can fill in any ENQUIRY form with your query(s) and we will get back to you with help." 
    },
    {
      question: "What is your payment schedule?",
      answer: "Our payment schedule typically includes an initial deposit upon project initiation, followed by milestone-based payments, and a final payment upon project completion."
    },
    {
      question: "Do you offer bulk discounts on offline marketing products?",
      answer: "Yes, our clients usually choose to buy products in bulk to avail amazing offers!!"
    },
    // Add more FAQs as needed
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
    
    <div className="heading">
              <h2>Frequently Asked Questions (FAQ)</h2>
            </div>

      <div>
        {faqs.map((faq, index) => (
          <div className="faq-item" key={index}>
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              {faq.question}
            </div>
            <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
