import React, { useState } from 'react';
import '../css/FAQ.css'; // Import the CSS file

const FAQ = () => {
  const faqs = [
    {
      question: "What is the process for starting a project with SJD Innovative?",
      answer: "The process typically starts with an initial consultation to understand your needs, followed by a proposal outlining our approach, timeline, and costs. Once agreed upon, we proceed with the project execution."
    },
    {
      question: "What is your process for working on a project?",
      answer: "Different products involves different processes. Mainly our process involves printing, fabrication, mounting, and installation for each product."
    },
    {
      question: "Do you offer PAN India services?",
      answer: "Yes, we offer service to most states through our widely spread vendors."
    },
    {
      question: "What are your rates?",
      answer: "Rates depend on the various factors such as quantity, number of days of activity, location type of material, etc. Contact us for more details!! "
    },
    {
      question: "Do you offer design services for my offline marketing materials?",
      answer: "Our team of designers is committed to understanding your requirements and creating designs that meet them."
    },

    // Add more FAQs as needed
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
    
    <div className="heading">
              <h2>Frequently Asked Questions (FAQ)</h2>
            </div>

      <div>
        {faqs.map((faq, index) => (
          <div className="faq-item" key={index}>
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              {faq.question}
            </div>
            <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
