import React from "react";
import "../css/Blog.css";
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';

// Access the base URL from the environment variable
const BASE_URL = process.env.REACT_APP_BASE_URL;

const BlogBody = ({ _id, title, summary, cover }) => {

  // Construct the full image URL
  // const imageURL = `${BASE_URL}${cover.replace(/\\/g, '/')}`;

  // console.log("Cover URL:", imageURL); // Log the full image URL

  return (
    <>
      <SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="signages, lollipop flange boards, standees, best canopies, blockout, pamphlets, neon sign boards,
        flex boards, sandwich boards, Pillar Branding"
      />

      <div className="post-container">
        <div className="thumbnail bg-white p-5">
          <div className="thumbnail-img">
            <h3>
              Dariyath Blogs <span>Since 2016</span>
            </h3>
            <div className="img-cont">
              <Link to={`/blog/${_id}`}>
                <img src = {cover} alt="blog-img" />
              </Link>
            </div>
          </div>
          {/* <!--/.thumbnail-img--> */}
          <div className="caption">
            <div className="blog-txt">
              <Link to={`/blog/${_id}`}>
                <h5>{title}</h5>
              </Link>
              <p>{summary}</p>
              <Link to={`/blog/${_id}`}>Read More</Link>
            </div>
            {/* <!--/.blog-txt--> */}
          </div>
          {/* <!--/.caption--> */}
        </div>
        {/* <!--/.thumbnail--> */}
      </div>
      {/* <!--/.col--> */}
    </>
  );
};

export default BlogBody;
