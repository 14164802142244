import {React, useEffect, useState} from 'react';
import Navbar from './Navbar';
import { useParams } from 'react-router-dom';
import { motion } from "framer-motion";
import '../css/SinglePost.css';

const SingleBlog = () => {
  const [postInfo, setPostInfo] = useState(null);
  const {id} = useParams();

  useEffect(() => {
    fetch(`https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/blogs/getbyId?id=${id}`)
    .then(response => {
      response.json()
        .then(postInfo => {
          setPostInfo(postInfo);
          // console.log('', postInfo)
      });
    });
  }, [id]);

  if(!postInfo) return '';

  return (
    <>
        <Navbar/>
        <motion.div
        className='singlePost-cont'
        initial={{ opacity: 0, y: "-100vh" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "-100vh" }}
        transition={{ duration: 0.5 }}
      >
  
        <div className='singlePost-image'>
          <img src={`${postInfo.cover}`} alt="" />
        </div>

        <div className="singlePost-body">
          <h2>{postInfo.title}</h2>        
          <div className="singlePost-content" dangerouslySetInnerHTML={{__html: postInfo.content}} />
        </div>
      </motion.div>
    </>
  )
}

export default SingleBlog
