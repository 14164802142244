import {React, useState, useEffect}from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import Model from "react-modal";
import Swal from "sweetalert2";
import '../css/Products.css';
import "../css/AdvertisementEnquiry.css";
import { redBorder } from '../functions/Validator';
import { changeBorder } from '../functions/Validator';
import ReactGA from "react-ga";
import FAQ from "./FAQ_Products";
import "../css/Home.css";

import standeeSmallest from "../components/Products/All/standee.png";
import standeeSmall from "../components/Products/All/standee.png";
import standeeBig from "../components/Products/All/standee.png";
import standeeLarge from "../components/Products/All/standee.png";

import easelstandeeSmallest from "../components/Products/All/standee2.png";
import easelstandeeSmall from "../components/Products/All/standee2.png";
import easelstandeeBig from "../components/Products/All/standee2.png";
import easelstandeeLarge from "../components/Products/All/standee2.png";

import bagSmallest from "../components/Products/All/nonlitflange.png";
import bagSmall from "../components/Products/All/nonlitflange.png";
import bagBig from "../components/Products/All/nonlitflange.png";
import bagLarge from "../components/Products/All/nonlitflange.png";

import gateArchSmallest from "../components/Products/All/gatearch.png";
import gateArchSmall from "../components/Products/All/gatearch.png";
import gateArchBig from "../components/Products/All/gatearch.png";
import gateArchLarge from "../components/Products/All/gatearch.png";

import signageSmallest from "../components/Products/All/signage.png";
import signageSmall from "../components/Products/All/signage.png";
import signageBig from "../components/Products/All/signage.png";
import signageLarge from "../components/Products/All/signage.png";

import cubeBoxSmallest from "../components/Products/All/cubebox.png";
import cubeBoxSmall from "../components/Products/All/cubebox.png";
import cubeBoxBig from "../components/Products/All/cubebox.png";
import cubeBoxLarge from "../components/Products/All/cubebox.png";

import tshirtsSmallest from "../components/Products/Tshirts/100-65.jpg";
import tshirtsSmall from "../components/Products/Tshirts/150-100.jpg";
import tshirtsBig from "../components/Products/Tshirts/200-100.jpg";
import tshirtsLarge from "../components/Products/Tshirts/350-200.jpg";

import bottleSmallest from "../components/Products/Bottle/100-65.jpg";
import bottleSmall from "../components/Products/Bottle/150-100.jpg";
import bottleBig from "../components/Products/Bottle/200-100.jpg";
import bottleLarge from "../components/Products/Bottle/350-200.jpg";

import mugSmallest from "../components/Products/Mug/100-65.jpg";
import mugSmall from "../components/Products/Mug/150-100.jpg";
import mugBig from "../components/Products/Mug/200-100.jpg";
import mugLarge from "../components/Products/Mug/350-200.jpg";

import dairySmallest from "../components/Products/Dairy/100-65.jpg";
import dairySmall from "../components/Products/Dairy/150-100.jpg";
import dairyBig from "../components/Products/Dairy/200-100.jpg";
import dairyLarge from "../components/Products/Dairy/350-200.jpg";

import capSmallest from "../components/Products/Cap/100-65.jpg";
import capSmall from "../components/Products/Cap/150-100.jpg";
import capBig from "../components/Products/Cap/200-100.jpg";
import capLarge from "../components/Products/Cap/350-200.jpg";

import calenderSmallest from "../components/Products/Calender/100-65.jpg";
import calenderSmall from "../components/Products/Calender/150-100.jpg";
import calenderBig from "../components/Products/Calender/200-100.jpg";
import calenderLarge from "../components/Products/Calender/350-200.jpg";

import mousepadSmallest from "../components/Products/Mousepad/100-65.jpg";
import mousepadSmall from "../components/Products/Mousepad/150-100.jpg";
import mousepadBig from "../components/Products/Mousepad/200-100.jpg";
import mousepadLarge from "../components/Products/Mousepad/350-200.jpg";

import penSmallest from "../components/Products/Pen/100-65.jpg";
import penSmall from "../components/Products/Pen/150-100.jpg";
import penBig from "../components/Products/Pen/200-100.jpg";
import penLarge from "../components/Products/Pen/350-200.jpg";

import totebagSmallest from "../components/Products/All/nopark.png";
import totebagSmall from "../components/Products/All/nopark.png";
import totebagBig from "../components/Products/All/nopark.png";
import totebagLarge from "../components/Products/All/nopark.png";

import stickerSmallest from "../components/Products/All/stickers.png";
import stickerSmall from "../components/Products/All/stickers.png";
import stickerBig from "../components/Products/All/stickers.png";
import stickerLarge from "../components/Products/All/stickers.png";

import cutoutSmallest from "../components/Products/All/cutout.png";
import cutoutSmall from "../components/Products/All/cutout.png";
import cutoutBig from "../components/Products/All/cutout.png";
import cutoutLarge from "../components/Products/All/cutout.png";

import pamphletSmallest from "../components/Products/All/pamphlet.png";
import pamphletSmall from "../components/Products/All/pamphlet.png";
import pamphletBig from "../components/Products/All/pamphlet.png";
import pamphletLarge from "../components/Products/All/pamphlet.png";

import canopySmallest from "../components/Products/All/canopy.png";
import canopySmall from "../components/Products/All/canopy.png";
import canopyBig from "../components/Products/All/canopy.png";
import canopyLarge from "../components/Products/All/canopy.png";

import backdropSmallest from "../components/Products/All/backdrop.png";
import backdropSmall from "../components/Products/All/backdrop.png";
import backdropBig from "../components/Products/All/backdrop.png";
import backdropLarge from "../components/Products/All/backdrop.png";

import promotableSmallest from "../components/Products/All/promotable.png";
import promotableSmall from "../components/Products/All/promotable.png";
import promotableBig from "../components/Products/All/promotable.png";
import promotableLarge from "../components/Products/All/promotable.png";

import lollipopSmallest from "../components/Products/All/lollipop.png";
import lollipopSmall from "../components/Products/All/lollipop.png";
import lollipopBig from "../components/Products/All/lollipop.png";
import lollipopLarge from "../components/Products/All/lollipop.png";

import ledblackoutSmallest from "../components/Products/All/blockout.png";
import ledblackoutSmall from "../components/Products/All/blockout.png";
import ledblackoutBig from "../components/Products/All/blockout.png";
import ledblackoutLarge from "../components/Products/All/blockout.png";

import officebagSmallest from "../components/Products/OfficeBag/100-65.jpg";
import officebagSmall from "../components/Products/OfficeBag/150-100.jpg";
import officebagBig from "../components/Products/OfficeBag/200-100.jpg";
import officebagLarge from "../components/Products/OfficeBag/350-200.jpg";

import brandumbrellaSmallest from "../components/Products/All/umbrella.png";
import brandumbrellaSmall from "../components/Products/All/umbrella.png";
import brandumbrellaBig from "../components/Products/All/umbrella.png";
import brandumbrellaLarge from "../components/Products/All/umbrella.png";

import lookwalkerSmallest from "../components/Products/All/lookwalker.png";
import lookwalkerSmall from "../components/Products/All/lookwalker.png";
import lookwalkerBig from "../components/Products/All/lookwalker.png";
import lookwalkerLarge from "../components/Products/All/lookwalker.png";

import mobilevanSmallest from "../components/Products/All/mobilevan.png";
import mobilevanSmall from "../components/Products/All/mobilevan.png";
import mobilevanBig from "../components/Products/All/mobilevan.png";
import mobilevanLarge from "../components/Products/All/mobilevan.png";

import autobrandingSmallest from "../components/Products/All/auto.png";
import autobrandingSmall from "../components/Products/All/auto.png";
import autobrandingBig from "../components/Products/All/auto.png";
import autobrandingLarge from "../components/Products/All/auto.png";

import metroSmallest from "../components/Products/All/metro.png";
import metroSmall from "../components/Products/All/metro.png";
import metroBig from "../components/Products/All/metro.png";
import metroLarge from "../components/Products/All/metro.png";

import busSmallest from "../components/Products/All/bus.png";
import busSmall from "../components/Products/All/bus.png";
import busBig from "../components/Products/All/bus.png";
import busLarge from "../components/Products/All/bus.png";

import pillarSmallest from "../components/Products/All/pillar.png";
import pillarSmall from "../components/Products/All/pillar.png";
import pillarBig from "../components/Products/All/pillar.png";
import pillarLarge from "../components/Products/All/pillar.png";

import sandwichSmallest from "../components/Products/All/sandwich.png";
import sandwichSmall from "../components/Products/All/sandwich.png";
import sandwichBig from "../components/Products/All/sandwich.png";
import sandwichLarge from "../components/Products/All/sandwich.png";

import ledSmallest from "../components/Products/All/led.png";
import ledSmall from "../components/Products/All/led.png";
import ledBig from "../components/Products/All/led.png";
import ledLarge from "../components/Products/All/led.png";

import autosample from "../components/Sample/Branding/auto.jpeg";
import bussample from "../components/Sample/Branding/bus.jpeg";
import flexsample from "../components/Sample/Branding/flex.jpeg";
import gatearchsample from "../components/Sample/Branding/gatearch.jpeg";
import lookwalkersample from "../components/Sample/Branding/lookwalker.jpeg";
import mobilevansample from "../components/Sample/Branding/mobilevan.jpeg";
import neonsample from "../components/Sample/Branding/neon.jpeg";
import pylonsample from "../components/Sample/Branding/pylon.jpg";
import umbrellasample from "../components/Sample/Branding/umbrella.jpeg";

import tshirtsample from "../components/Services/College/01.jpg";
import bottlesample from "../components/Services/College/03.jpg";
import cupsample from "../components/Services/TechPark/05.jpg";
import dairysample from "../components/Sample/Corporate/dairy.jpeg";
import capsample from "../components/Sample/Corporate/cap.jpeg";
import callendersample from "../components/Sample/Corporate/callender.jpeg";
import bagsample from "../components/Sample/Corporate/bag.jpeg";
import pensample from "../components/Services/School/04.jpg";
import mousepadsample from "../components/Sample/Corporate/mousepad.jpeg";

import standeesample from "../components/Sample/Custom/standee.jpg";
import ledstandeesample from "../components/Sample/Custom/ledstandee.jpeg";
import carrybagsample from "../components/Sample/Custom/carrybag.jpeg";
import cutoutsample from "../components/Sample/Custom/cutout.jpg";
import pamphletsample from "../components/Sample/Custom/pamphlets.jpg";
import canopysample from "../components/Services/Society/04.jpg";
import totebagsample from "../components/Sample/Custom/totebag.jpeg";
import stickerssample from "../components/Sample/Custom/stickers.jpg";
import backdropsample from "../components/Sample/Custom/backdrop.jpg";

import SEO from '../components/SEO';

const Products = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  },[]);

    const [activeTab, setActiveTab] = useState('custom-products');
    const [customLive, setCustomLive] = useState(false);
    const [giftsLive, setGiftsLive] = useState(false);
    const [brandingLive, setBrandingLive] = useState(false);
    const [enquiryShow, setEnquiryShow] = useState(false);

    const handleClick = (ActiveContainer) => {
      setActiveTab(ActiveContainer);
    };

    const handleCustProduct = () => {
        setCustomLive(!customLive)
    }

    const handleCorpoarteGifts = () => {
        setGiftsLive(!giftsLive)
    }

    const handleBranding = () => {
        setBrandingLive(!brandingLive)
    }

    const data = [
      {
        image: require('../components/Cases/coke.jpeg'), // Replace with your image path
        heading: '"Share a Coke" campaign by CocaCola',
        paragraph: 'Coca-Cola replaced its iconic logo with popular names on its bottles and cans, encouraging customers to find and share a Coke with their friends and family. This campaign boosted sales by over 2% in the U.S. and significantly increased social media engagement as people shared photos of their personalized bottles.',
        link: "https://www.digitalvidya.com/blog/case-study-on-coca-colas-share-a-coke-campaign/"
      },
      {
        image: require('../components/Cases/nike.jpeg'), // Replace with your image path
        heading: '"Just Do It" Billboards by Nike',
        paragraph: ' The iconic "Just Do It" billboards and print ads helped establish the brand as a symbol of athletic excellence and personal achievement. This slogan, combined with high-profile athlete endorsements, propelled Nike to the top of the sportswear industry.',
        link: "https://worldbrandaffairs.com/how-nikes-just-do-it-campaign-became-a-global-phenomenon/"
      },
      {
        image: require('../components/Cases/kfc.jpeg'), // Replace with your image path
        heading: 'Hot & Spicy Billboard by KFC',
        paragraph: 'KFC used creative billboards that turned everyday objects (like a fire truck) into spicy fried chicken pieces. This innovative approach garnered a lot of media attention and public interest, boosting brand visibility and customer engagement.',
        link : "https://the-media-leader.com/kfc-focusses-on-impact-with-smoke-billboard-stunt/"
      },
      {
        image: require('../components/Cases/p&g.jpeg'), // Replace with your image path
        heading: '"Thank You, Mom" campaign by P&G',
        paragraph: 'Procter & Gamble launched a heartwarming "Thank You, Mom" campaign during the Olympics, which included TV commercials, print ads, and in-store promotions. This campaign not only increased sales but also strengthened the emotional connection between the brand and its consumers.',
        link : "https://news.pg.com/news-releases/news-details/2016/Strong-Moms-Inspire-Latest-PG-Thank-You-Mom-Campaign/default.aspx"
      },
    ];

    // form fields
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('');
  const [prodName, setProdName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [budget, setBudget] = useState('');
  const [details, setDetails] = useState('');

  const ProdEnquiryHandler = async(ev) => {
    var status = 0;
    ev.preventDefault();

    // Form text verification
    if (status==0){
      const isTextValid = /^[A-Za-z\s]{4,}$/.test(name);
      const isMailValid = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/.test(mail);
      const isNumberValid = /^[6-9][0-9]{9}$/.test(phone);
      const isBudgetValid = /^[1-9][0-9]{1,8}$/.test(budget);
      const isQuantityValid = /^[1-9]\d{1,5}$/.test(quantity);
      const isDetailsValid = /^[a-zA-Z\s0-9.-]+$/.test(details);
      
      // Check if the textbox has valid name or not
      const textBox = document.getElementById("name");
      if (!isTextValid){     
      redBorder(textBox)                
      Swal.fire({
          title: "Validation!",
          text: "Name Must have minimum 4 letters. You cannot use numbers or any other special characters with name.",//"Error Ocurred. Try After Sometime",
          icon: "warning",
          customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
      })               
      }

      // check if the mail has valid mail address or not
      const mailBox = document.getElementById("email");
      if(!isMailValid){
        redBorder(mailBox) 
        Swal.fire({
          title: "Validation!",
          text: "Mail format is wrong. For Example: name@gmail.com",//"Error Ocurred. Try After Sometime",
          icon: "warning",
          customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
      })                           
      }else{
        changeBorder(mailBox)
      }

      // check if the number box is valid or not
      const phoneBox = document.getElementById("phone");
      if(!isNumberValid){
        redBorder(phoneBox)
        Swal.fire({
          title: "Validation!",
          text: "Phone number should start from 6-9. It should have 10 numbers. No letters are allowed.",//"Error Ocurred. Try After Sometime",
          icon: "warning",
          customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
      })     
      }else{
        changeBorder(phoneBox)
      }

      const budgetBox = document.getElementById("budget");
      if(!isBudgetValid){
        redBorder(budgetBox)
        Swal.fire({
          title: "Validation!",
          text: "Budget must have atleast two digits. Letters and special characters are not allowed in budget.",//"Error Ocurred. Try After Sometime",
          icon: "warning",
          customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
      })    
      }else{
        changeBorder(budgetBox)
      }

      const quantityBox = document.getElementById("quantity");
      if(!isQuantityValid){
        redBorder(quantityBox)
        Swal.fire({
          title: "Validation!",
          text: "Quantity must have atleast two digits. Letters and special characters are not allowed in quantity.",//"Error Ocurred. Try After Sometime",
          icon: "warning",
          customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
      })    
      }else{
        changeBorder(quantityBox)
      }

      const detailsBox = document.getElementById("details");
      if(!isDetailsValid){
        redBorder(detailsBox)
        Swal.fire({
          title: "Validation!",
          text: "You cannot use special characters in requirement details.",//"Error Ocurred. Try After Sometime",
          icon: "warning",
          customClass: {
              confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
      })
      }else{
        changeBorder(detailsBox);
      }
      
      if(isTextValid && isMailValid && isNumberValid && isBudgetValid && isQuantityValid && isDetailsValid){
        status=1;
      }
    }
    
    if(status==1){            
      try{
        const response = await fetch('https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/postProdQuery', {
          method:'POST',
          body: JSON.stringify({name,mail,phone,prodName,quantity,budget,details}),
          headers:{
            "Content-Type": "application/json"
          },
          })
          if(response.status === 200){
            Swal.fire({
              title: "Products",
              text: "Enquiry Submitted!",
              icon: "success",
              customClass: {
                confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
              }
            });
            setName('');
            setMail('');
            setPhone('');
            setProdName('');
            setBudget('');
            setQuantity('');
            setDetails('');
            setEnquiryShow(false);          
          }else{
            Swal.fire({
              title: "Enquiry",
              text: response.status,//"Oops! Enquiry Failed.",
              icon: "warning",
              customClass: {
                confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
              }
            });
          }
      }catch(e){
        Swal.fire({
          title: "Enquiry!",
          text: e,//"Error Ocurred. Try After Sometime",
          icon: "error",
          customClass: {
            confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
        });
      }
  } 
    
  } 
  return (
    <>

<SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="Standee,
        Signages,
        Canopy,
        pamphlets,
        blockout board,
        clip-on board,
        Lollipop flange board,
        gate arch,
        umbrella branding,
        pillar branding,
        led board,
        neno sign
        "
      />

            <div>
              {/* <input type="button" value="Open Model" onClick={()=>setEnquiryShow(true)}/> */}
              <Model
                isOpen={enquiryShow}
                className="enquiry-box-container centered-popup"
              >
                {/* <div className="enquiry-box-container centered-popup"> */}
                <div className="enquiry-box">
                  <h2 className="text-center">Enquire</h2>
                  <i
                    class="fa fa-times text-white close-btn enquiry-close"
                    aria-hidden="true"
                    onClick={() => setEnquiryShow(false)}
                  ></i>
                  <form action="" onSubmit={ProdEnquiryHandler}>
                    <div className="input">
                      <input 
                        type="text" 
                        name="name" 
                        placeholder="Name"
                        id="name"
                        value={name}
                        required="true"
                        onChange = { ev => setName(ev.target.value)}
                       />
                    </div>
                    <div className="input">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email Address"
                        value={mail}
                        required="true"
                        onChange={ ev => setMail(ev.target.value)}
                      />
                    </div>
                    <div className="input">
                      <input 
                        type="text" 
                        name="phone" 
                        id="phone"
                        maxLength={10}
                        placeholder="Phone Number" 
                        value={phone}
                        onChange={ ev => setPhone(ev.target.value)}  
                      />
                    </div>
                    <div className="input">
                      <select name="prodName" id="" onChange={ev => setProdName(ev.target.value)} required="true">
                        <option value="Custom Products">Custom Products</option>
                        <option value="Corporate Gifts">Corporate Gifts</option>
                        <option value="Branding Products">Branding Products</option>
                      </select>
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        name="Quantity"
                        id="quantity"
                        placeholder="Number of pieces you want"
                        value={quantity}
                        maxLength={5}
                        required="true"
                        onChange={ ev => setQuantity(ev.target.value)}
                      />
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        name="budget"
                        id="budget"
                        placeholder="Quote your budget in Rs."
                        value={budget}
                        required="true"
                        maxLength={5}
                        onChange={ev => setBudget(ev.target.value)}
                      />
                    </div>
                    <div className="input" id="text-area">
                      <textarea
                        name="details"
                        id="details"
                        cols="30"
                        rows="1"
                        placeholder="Write all your requirements and queries.."
                        value={details}
                        required="true"
                        onChange={ev=>setDetails(ev.target.value)}
                      ></textarea>
                    </div>
                    <div className="input">
                      <input
                        type="submit"
                        value="Enquiry"
                        className="sub-but"
                      />
                    </div>
                  </form>
                </div>
                {/* </div> */}
              </Model>
            </div>

    <Navbar/>

    <motion.div className="container-fluid product-page position-relative overflow-hidden p-md-5 m-md text-center"
      initial={{ opacity: 0, y: "-100vh" }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: "-100vh" }}
      transition={{ duration: 0.5 }}
    >

        <div className="product-intro">  
        
        <h1> Every product is personalised for you.</h1>

        <div className="d-flex gap-3 justify-content-center lead fw-normal">
            
        </div>
        </div>
              

        <div className="products-container" id='products-container'>        
            <div className="item-category   d-md-flex flex-md-equal w-100 my-md-3 ps-md-3">

                 <p id={activeTab === 'custom-products' ? 'active' : ''} onClick={() => handleClick('custom-products')}>Custom Products</p>
                 <p id={activeTab === 'corporate-gifts' ? 'active' : ''} onClick={() => handleClick('corporate-gifts')}>Corporate Gifts</p>
                 <p id={activeTab === 'Branding-products' ? 'active' : ''} onClick={() => handleClick('Branding-products')}>Branding Products</p>

            </div>

            {activeTab === 'custom-products' && (
                <div className="product px-3 px-md-5  overflow-hidden" id="custom-products">
                    <div className="item">
                    <img src={standeeLarge} alt="rollup standee" className='img'/>
                        <img src={standeeBig} alt="standee banner" className='img'/>
                        <img src={standeeSmall} alt="product display standee" className='img'/>
                        <img src={standeeSmallest} alt="standee display" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Customise your rollup standee on lowest price.
                                <br />
                                <span className='style-link' onClick={handleCustProduct}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                    <img src={easelstandeeLarge} alt="digital standee" className='img'/>
                        <img src={easelstandeeBig} alt="advertising digital standee" className='img'/>
                        <img src={easelstandeeSmall} alt="digital display standee" className='img'/>
                        <img src={easelstandeeSmallest} alt="digital led standee" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Customise your easel standee on lowest price.
                                <br />
                                <span className='style-link' onClick={handleCustProduct}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <img src={bagLarge} alt="carry bag printing near me" className='img'/>
                        <img src={bagBig} alt="printed carry bag design" className='img'/>
                        <img src={bagSmall} alt="personalized gift bags bulk" className='img'/>
                        <img src={bagSmallest} alt="custom printed kraft bags" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Customise your Non-Lit Flange Board on lowest price.
                                <br />
                                <span className='style-link' onClick={handleCustProduct}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <img src={totebagLarge} alt="custom grocery tote bags" className='img'/>
                        <img src={totebagBig} alt="cheap custom totes" className='img'/>
                        <img src={totebagSmall} alt="high quality tote bag printing" className='img'/>
                        <img src={totebagSmallest} alt="personalized shopping bags bulk" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Customise your no parking boards at lowest price.
                                    <br />
                                <span className='style-link' onClick={handleCustProduct}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <img src={stickerLarge} alt="sticker making shop near me" className='img'/>
                        <img src={stickerBig} alt="nearest sticker printing shop" className='img'/>
                        <img src={stickerSmall} alt="qr code sticker" className='img'/>
                        <img src={stickerSmallest} alt="print banner near me" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                            <p>
                                Customise your stickers on lowest price.
                                <br />
                                <span className='style-link' onClick={handleCustProduct}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <img src={cutoutLarge} alt="cutout banner design" className='img'/>
                        <img src={cutoutBig} alt="cut out posters" className='img'/>
                        <img src={cutoutSmall} alt="cut out printing near me" className='img'/>
                        <img src={cutoutSmallest} alt="sunboard cutout standee" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Customise your cutout on lowest price.
                                    <br />
                                <span className='style-link' onClick={handleCustProduct}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <img src={pamphletLarge} alt="pamphlet printing near me" className='img'/>
                        <img src={pamphletBig} alt="pamphlet printing cost" className='img'/>
                        <img src={pamphletSmall} alt="flyer and leaflet printing" className='img'/>
                        <img src={pamphletSmallest} alt="pamphlet printing price" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Customise your pamphlet on lowest price.
                                    <br />
                                <span className='style-link' onClick={handleCustProduct}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <img src={canopyLarge} alt="canopy design" className='img'/>
                        <img src={canopyBig} alt="canopy printing" className='img'/>
                        <img src={canopySmall} alt="house canopy design" className='img'/>
                        <img src={canopySmallest} alt="entrance canopy design" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Customise your canopy on lowest price.
                                    <br />
                                <span className='style-link' onClick={handleCustProduct}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <img src={backdropLarge} alt="backdrop design" className='img'/>
                        <img src={backdropBig} alt="backdrop printing services" className='img'/>
                        <img src={backdropSmall} alt="stage background design" className='img'/>
                        <img src={backdropSmallest} alt="fabric banner backdrop" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Customise your Backdrop on lowest price.
                                    <br />
                                <span className='style-link' onClick={handleCustProduct}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <img src={promotableLarge} alt="retractable banner table top" className='img'/>
                        <img src={promotableBig} alt="table top roller banner" className='img'/>
                        <img src={promotableSmall} alt="custom printed table tops" className='img'/>
                        <img src={promotableSmallest} alt="tabletop vinyl printing bangalore" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Customise your table top on lowest price.
                                    <br />
                                <span className='style-link' onClick={handleCustProduct}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <img src={lollipopLarge} alt="lollipop printing" className='img'/>
                        <img src={lollipopBig} alt="lollipop print out" className='img'/>
                        <img src={lollipopSmall} alt="lollipop signage" className='img'/>
                        <img src={lollipopSmallest} alt="led lollipop sign board" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Customise your lollipop on lowest price.
                                    <br />
                                <span className='style-link' onClick={handleCustProduct}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <img src={ledblackoutLarge} alt="Led Blackout Signage Board" className='img'/>
                        <img src={ledblackoutBig} alt="led blackout board" className='img'/>
                        <img src={ledblackoutSmall} alt="Square LED Blackout Board" className='img'/>
                        <img src={ledblackoutSmallest} alt="blackout sign board" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Customise your led blackout on lowest price.
                                    <br />
                                <span className='style-link' onClick={handleCustProduct}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            )}                

                {activeTab === 'corporate-gifts' && (
                    <div className="product px-3 px-md-5 overflow-hidden" id="corporate-gifts">
                        <div className="item text-dark">
                          <img src={tshirtsLarge} alt="tshirt printing" className='img'/>
                          <img src={tshirtsBig} alt="t shirt logo printing " className='img'/>
                          <img src={tshirtsSmall} alt="tshirt logo printing embroidery" className='img'/>
                          <img src={tshirtsSmallest} alt="tshirt printing business logo" className='img'/>
                          <div className="cta-but-container text-center">
                              <div className="cta-but">
                              <p>
                                      Gift customised tshirts in lowest price.
                                  <br />
                                  <span className='style-link' onClick={handleCorpoarteGifts}>
                                          Live Preview                                                                              
                                  </span>
                                  <br />
                                  <span className='enquiry-span'>
                                    Enquiry
                                    <br />
                                    <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                  </span>
                                  </p>
                              </div>
                          </div>
                        </div>
                      <div className="item">
                        <img src={bottleLarge} alt="bottle printing" className='img'/>
                        <img src={bottleBig} alt="custom bottle printing" className='img'/>
                        <img src={bottleSmall} alt="plastic bottle printing" className='img'/>
                        <img src={bottleSmallest} alt="bottle printing design" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Gift customised bottle in lowest price.
                                <br />
                                <span className='style-link' onClick={handleCorpoarteGifts}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                      </div>
                      <div className="item">
                        <img src={mugLarge} alt="mug printing" className='img'/>
                        <img src={mugBig} alt="mug printing shop near me" className='img'/>
                        <img src={mugSmall} alt="mug printing designs" className='img'/>
                        <img src={mugSmallest} alt="cup print shop near me" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Gift customised mugs in lowest price.
                                <br />
                                <span className='style-link' onClick={handleCorpoarteGifts}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                      </div>
                      <div className="item">
                        <img src={dairyLarge} alt="diary printing" className='img'/>
                        <img src={dairyBig} alt="diary printing near me" className='img'/>
                        <img src={dairySmall} alt="customized diary printing near me" className='img'/>
                        <img src={dairySmallest} alt="online diary printing" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Gift customised dairy in lowest price.
                                <br />
                                <span className='style-link' onClick={handleCorpoarteGifts}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                      </div>
                      <div className="item">
                        <img src={capLarge} alt="cap printing" className='img'/>
                        <img src={capBig} alt="cap printing near me" className='img'/>
                        <img src={capSmall} alt="cap printing design" className='img'/>
                        <img src={capSmallest} alt="cap printing services" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Gift customised caps in lowest price.
                                <br />
                                <span className='style-link' onClick={handleCorpoarteGifts}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                      </div>
                      <div className="item">
                        <img src={calenderLarge} alt="calendar printing" className='img'/>
                        <img src={calenderBig} alt="calendar printing near me" className='img'/>
                        <img src={calenderSmall} alt="calendar printing cost" className='img'/>
                        <img src={calenderSmallest} alt="calendar printing services" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Gift customised callender in lowest price.
                                <br />
                                <span className='style-link' onClick={handleCorpoarteGifts}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                      </div>
                      <div className="item">
                      <img src={officebagLarge} alt="office bag printing" className='img'/>
                        <img src={officebagBig} alt="office bag printing bangalore" className='img'/>
                        <img src={officebagSmall} alt="office bag printing cost" className='img'/>
                        <img src={officebagSmallest} alt="bag printing near me" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Gift customised office bag in lowest price.
                                <br />
                                <span className='style-link' onClick={handleCorpoarteGifts}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                      </div>
                      <div className="item">
                        <img src={penLarge} alt="pen printing" className='img'/>
                        <img src={penBig} alt="advertising pens" className='img'/>
                        <img src={penSmall} alt="pen printing near me" className='img'/>
                        <img src={penSmallest} alt="promotional pen printing near me" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Gift pen with customised prints in lowest price.
                                <br />
                                <span className='style-link' onClick={handleCorpoarteGifts}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                      </div>
                      <div className="item">
                        <img src={mousepadLarge} alt="mouse pad printing" className='img'/>
                        <img src={mousepadBig} alt="mouse pad printing near me" className='img'/>
                        <img src={mousepadSmall} alt="mouse pad printing design" className='img'/>
                        <img src={mousepadSmallest} alt="mouse pad printing cost" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Gift customised mouse pad in lowest price.
                                <br />
                                <span className='style-link' onClick={handleCorpoarteGifts}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                      </div>
                      {/*
                      <div className="item">
                        <img src={umbrellaLarge} alt="#DigitalLedBranding" className='img'/>
                        <img src={umbrellaBig} alt="#DigitalLedBranding" className='img'/>
                        <img src={umbrellaSmall} alt="#DigitalLedBranding" className='img'/>
                        <img src={umbrellaSmallest} alt="#DigitalLedBranding" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Gift umbrella with customised printing in lowest price.
                                <br />
                                <span className='style-link' onClick={handleCorpoarteGifts}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                      </div>
                    */}
                      {/*
                      <div className="item">
                      <img src={capLarge} alt="#DigitalLedBranding" className='img'/>
                        <img src={capBig} alt="#DigitalLedBranding" className='img'/>
                        <img src={capSmall} alt="#DigitalLedBranding" className='img'/>
                        <img src={capSmallest} alt="#DigitalLedBranding" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Gift customised tshirts in lowest price.
                                <br />
                                <span className='style-link' onClick={handleCorpoarteGifts}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                      </div>
                    */}
                      {/*
                      <div className="item">
                        <img src={capLarge} alt="#DigitalLedBranding" className='img'/>
                        <img src={capBig} alt="#DigitalLedBranding" className='img'/>
                        <img src={capSmall} alt="#DigitalLedBranding" className='img'/>
                        <img src={capSmallest} alt="#DigitalLedBranding" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                    Gift customised tshirts in lowest price.
                                <br />
                                <span className='style-link' onClick={handleCorpoarteGifts}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                      </div>
                      */}
                    </div>
                )}

                {activeTab === 'Branding-products' && (
                    <div className="product px-3 px-md-5  overflow-hidden" id='Branding-products'>
                        <div className="item">
                        <div className="item text-dark">
                        <img src={gateArchLarge} alt="2D promotion non-lit arch gate" className='img'/>
                        <img src={gateArchBig} alt="arch with gate" className='img'/>
                        <img src={gateArchSmall} alt="Backlit arch gate" className='img'/>
                        <img src={gateArchSmallest} alt="2D arch gate for advertisements" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                            <p>
                                    Get GateArch for your business in best price.
                                <br />
                                <span className='style-link' onClick={handleBranding}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                        </div>
                        </div>
                        <div className="item">
                        <div className="item text-dark">
                        <img src={signageLarge} alt="signage printing" className='img'/>
                        <img src={signageBig} alt="signage board" className='img'/>
                        <img src={signageSmall} alt="signage display" className='img'/>
                        <img src={signageSmallest} alt="street signs" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                            <p>
                                Get signages for your business in best price.
                                <br />
                                <span className='style-link' onClick={handleBranding}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                        </div>
                        </div>
                        <div className="item">
                        <div className="item text-dark">
                        <img src={cubeBoxLarge} alt="cube box printing" className='img'/>
                        <img src={cubeBoxBig} alt="cube boxes designs" className='img'/>
                        <img src={cubeBoxSmall} alt="custom cube boxes printing" className='img'/>
                        <img src={cubeBoxSmallest} alt="personalised cube box" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                                <p>
                                Get cube box branding for your business in best price.
                                <br />
                                <span className='style-link' onClick={handleBranding}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                        </div>
                        </div>

                        <div className="item">
                        <div className="item text-dark">
                        <div className = "auto">
                        <img src={autobrandingLarge} alt="auto top branding" className='img'/>
                        <img src={autobrandingBig} alt="auto hood branding" className='img'/>
                        <img src={autobrandingSmall} alt="auto branding in bangalore" className='img'/>
                        <img src={autobrandingSmallest} alt="auto rickshaw branding" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                            <p>
                            Get Auto Branding for your business in best price.
                                <br />
                                <span className='style-link' onClick={handleBranding}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        
                        <div className="item">
                        <div className="item text-dark">
                        <img src={brandumbrellaLarge} alt="#DigitalLedBranding" className='img'/>
                        <img src={brandumbrellaBig} alt="#DigitalLedBranding" className='img'/>
                        <img src={brandumbrellaSmall} alt="#DigitalLedBranding" className='img'/>
                        <img src={brandumbrellaSmallest} alt="#DigitalLedBranding" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                            <p>
                              Get Umbrella Branding for your business in best price.
                                <br />
                                <span className='style-link' onClick={handleBranding}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                        </div>
                        </div>
                        <div className="item">
                        <div className="item text-dark">
                        <img src={lookwalkerLarge} alt="lookwalker activity" className='img'/>
                        <img src={lookwalkerBig} alt="lookwalker branding" className='img'/>
                        <img src={lookwalkerSmall} alt="lookwalker price" className='img'/>
                        <img src={lookwalkerSmallest} alt="lookwalker advertising" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                            <p>
                            Get Lookwalker Branding for your business in best price.
                                <br />
                                <span className='style-link' onClick={handleBranding}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                        </div>
                        </div>
                        <div className="item">
                        <div className="item text-dark">
                        <img src={mobilevanLarge} alt="van branding mobile" className='img'/>
                        <img src={mobilevanBig} alt="mobile van branding" className='img'/>
                        <img src={mobilevanSmall} alt="led van advertising" className='img'/>
                        <img src={mobilevanSmallest} alt="tata ace mobile van advertising" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                            <p>
                            Get MobileVan Branding for your business in best price.
                                <br />
                                <span className='style-link' onClick={handleBranding}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                        </div>
                        </div>
                        <div className="item">
                        <div className="item text-dark">
                        <img src={metroLarge} alt="metro branding" className='img'/>
                        <img src={metroBig} alt="metro marketing" className='img'/>
                        <img src={metroSmall} alt="metro advertising cost" className='img'/>
                        <img src={metroSmallest} alt="metro ads" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                            <p>
                            Get Metro Branding for your business in best price.
                                <br />
                                <span className='style-link' onClick={handleBranding}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                        </div>
                        </div>
                        <div className="item">
                        <div className="item text-dark">
                        <img src={busLarge} alt="bus advertisement" className='img'/>
                        <img src={busBig} alt="bus back panel advertising" className='img'/>
                        <img src={busSmall} alt="bus signage" className='img'/>
                        <img src={busSmallest} alt="ads on buses" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                            <p>
                            Get Bus Branding for your business in best price.
                                <br />
                                <span className='style-link' onClick={handleBranding}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                        </div>
                        </div>
                        <div className="item">
                        <div className="item text-dark">
                        <img src={pillarLarge} alt="pillar branding design" className='img'/>
                        <img src={pillarBig} alt="pillar branding mockup" className='img'/>
                        <img src={pillarSmall} alt="box pillar branding" className='img'/>
                        <img src={pillarSmallest} alt="office pillar branding" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                            <p>
                            Get Pillar Branding for your business in best price.
                                <br />
                                <span className='style-link' onClick={handleBranding}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                        </div>
                        </div>
                        <div className="item">
                        <div className="item text-dark">
                        <img src={ledLarge} alt="led board" className='img'/>
                        <img src={ledBig} alt="led board for shop" className='img'/>
                        <img src={ledSmall} alt="led board designs" className='img'/>
                        <img src={ledSmallest} alt="glowing sign board" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                            <p>
                            Get LED Baord for your business in best price.
                                <br />
                                <span className='style-link' onClick={handleBranding}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                        </div>
                        </div>
                        <div className="item">
                        <div className="item text-dark">
                        <img src={sandwichLarge} alt="sandwich board advertising custom sandwich board signs sandwich board signs outdoor" className='img'/>
                        <img src={sandwichBig} alt="sandwich board design sandwich board price" className='img'/>
                        <img src={sandwichSmall} alt="sandwich board signs sandwich board acrylic" className='img'/>
                        <img src={sandwichSmallest} alt="sandwich board ad sign sandwich" className='img'/>
                        <div className="cta-but-container text-center">
                            <div className="cta-but">
                            <p>
                            Get Sandwich Board for your business in best price.
                                <br />
                                <span className='style-link' onClick={handleBranding}>
                                        Live Preview                                                                              
                                </span>
                                <br />
                                <span className='enquiry-span'>
                                  Enquiry
                                  <br />
                                  <i class="fa fa-arrow-right text-dark" onClick={()=>setEnquiryShow(true)} aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Click to enquire about the product"></i>
                                </span>
                                </p>
                            </div>
                        </div>
                        </div>
                        </div>
                  
                    </div>
                )}

                {/* //todo: pictures are grouped according to category and showed in live preview */}

                   {customLive && (
                    <div className='live-pic-container'>
                                <i class="fa fa-times close-btn" aria-hidden="true"
                                onClick={handleCustProduct}></i>
                            <h2>Sample Pictures</h2>
                        <div className="product">
                            <div className="service-pics live-pics" >
            

              <div className="pics">
                <img src= {standeesample} alt="marketing standee" />
                 
              </div>
              
              <div className="pics">
                <img src= {backdropsample} alt="backdrop banner design" />
                
              </div>
              
              <div className="pics">
                <img src= {carrybagsample} alt=" carry bag printing design" />
                
              </div>

              <div className="pics">
                <img src={totebagsample} alt="personalised tote bags bulk" />
                 
              </div>
              
              <div className="pics">
                <img src={cutoutsample} alt="cutout printing" />
                
              </div>
              
              <div className="pics">
                <img src={stickerssample} alt="custom clear sticker labels" />
                
              </div>
              <div className="pics">
              <img src={pamphletsample} alt="pamphlet printing near me" />
              
            </div>
            <div className="pics">
            <img src={canopysample} alt="canopy making shop near me" />
            
          </div>
          <div className="pics">
          <img src={ledstandeesample} alt="digital signage standee" />
          
        </div>
              
                            </div>
                         </div>
                    </div>
                    )} 
        
                    {giftsLive && (
                    <div className='live-pic-container'>
                                <i class="fa fa-times close-btn" aria-hidden="true" onClick={handleCorpoarteGifts}></i>
                            <h2>Sample Pictures</h2>
                        <div className="product">
                            <div className="service-pics live-pics" >
              <div className="pics">
                <img src= {tshirtsample} alt="" />
                
              </div>
              <div className="pics">
              <img src= {bottlesample} alt=" tshirt printing bangalore" />
                
              </div>
              <div className="pics">
                <img src= {cupsample} alt="cup printing shop near me" />
                
              </div>
              <div className="pics">
                <img src= {dairysample} alt="custom diary printing" />
                
              </div>
              <div className="pics">
                <img src= {capsample} alt="cap printing price" />
                
              </div>
              <div className="pics">
                <img src= {callendersample} alt="calendar printing design" />
                
              </div>
              <div className="pics">
                <img src= {bagsample} alt="office bag printing bangalore" />
                
              </div>
              <div className="pics">
                <img src= {pensample} alt="pen printing near me pen printing services" />
                
              </div>
              <div className="pics">
                <img src= {mousepadsample} alt="mouse pad printing bulk" />
              </div>
              
                            </div>
                         </div>
                    </div>
                    )} 

                    {brandingLive && (
                    <div className='live-pic-container'>
                                <i class="fa fa-times close-btn" aria-hidden="true" onClick= {handleBranding}></i>
                            <h2>Sample Pictures</h2>
                        <div className="product">
                            <div className="service-pics live-pics" >
              <div className="pics">
                <img src= {autosample} alt="auto top branding" />
                
              </div>
              <div className="pics">
                <img src= {flexsample} alt="flexboard  flex board design" />
                
              </div>
              <div className="pics">
                <img src= {bussample} alt="bus signage" />
                
              </div>
              <div className="pics">
                <img src= {pylonsample} alt="office pillar branding" />
                
              </div>
              <div className="pics">
                <img src= {gatearchsample} alt="2D backlight gate arch acrylic board" />
                
              </div>
              <div className="pics">
                <img src= {lookwalkersample} alt="lookwalker branding" />
                
              </div>
              <div className="pics">
                <img src= {mobilevansample} alt="tata ace mobile van advertising" />
                
              </div>
              <div className="pics">
                <img src= {neonsample} alt="neon glow sign board" />
                
              </div>
              <div className="pics">
                <img src= {umbrellasample} alt="umbrella branding examples india" />
                
              </div>
             
                            </div>
                         </div>
                    </div>
                    )} 
         </div>   

    <br></br>
    <div className='success'>
    <h2>Wonders A Good Marketing Campaign Can Do!!</h2>
    <br></br>
    <div className="four-column-boxes">
      {data.map((item, index) => (
        <div key={index} className="column">
          <img src={item.image} alt={item.heading} />
          <h6>{item.heading}</h6>
          <p>
            {item.paragraph} <br></br>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              Learn more
            </a>
          </p>
        </div>
      ))}
    </div>
    </div>

         <FAQ/>
 
         </motion.div>
    </>
  )
}

export default Products
