import React from 'react'
import AdminPanel from './AdminPanel'
import {useEffect, useState} from "react";
import axios from 'axios';
import "../css/Users.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button } from "react-bootstrap";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SEO from '../components/SEO';

  const UserDisplayDelete = ({username, designation, mail}) => {

    const [users,  setUsers] = useState([]) 
      
    useEffect( ()=>{
      fetch('https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/postusers').then(response => {
        response.json().then(users => {
          setUsers(users);
        });
      })
    }, []);


      //to delete users from table

const deleteUser = ((user_name)=>{
  if(window.confirm("Do you want to remove?")){
      fetch("http://localhost:4000/users/"+user_name,
      {method:"DELETE"}).then(()=>{

        console.log('Data deleted successfully');
        window.location.reload();

      }).catch((err)=>{
          console.log(err.message)
      })
  }
})


    
    return (
      <>

<SEO
    title="Best Marketing and Advertising Company in Bangalore"
    description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
    keywords="college event,
    school events,
    society events
    rwa activities,
    atl activities,
    btl activities,
    atl and btl marketing,
    rwa society,
    techpark events,
    corporate events,
    "
  />

        <div className="admin-container">
          
            <div className='cont'>
            <div className="caption">
              <div className="offer-txt">
                  <h2>{username}</h2>
                  <p>{designation}</p>
                  <p>{mail}</p>
              </div>
              <div>         
                <FontAwesomeIcon icon= {faTrash} type="button" onClick={() => deleteUser(username)} ></FontAwesomeIcon>
                </div>
              {/* <!--/.blog-txt--> */}
            </div>


          {/*  <table id='users'> 
                <tr>
                  <thead>
                    <th>Name</th>
                    <th>Mail ID</th>
                    <th>Designation</th>
                    
                  </thead>
                  <tbody>
                      {
                          <tr>
                            <td>{username}</td>
                            <td>{mail}</td>
                            <td>{designation}</td>
                            <td>
                            <FontAwesomeIcon icon= {faTrash} type="button" onClick={() => deleteUser(username)} ></FontAwesomeIcon>                            </td>
                          </tr>
                        }
                  </tbody>
                </tr>
            </table>
                      */}
            </div>
        </div>
        
    </>
  )
}
export default UserDisplayDelete