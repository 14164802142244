import React from 'react'
import AdminPanel from './AdminPanel'
import axios from 'axios';
import {useEffect, useState} from "react";
import { Link, Navigate } from 'react-router-dom'
import "../css/QueryReceived.css"

import SEO from '../components/SEO';

//to fetch the users' queries

const ProductQuery = () => {

  const [object, setObj] = useState([
    {
      title: "",
      description: "",
      url: "",
    },
  ]);

  const [products,  setProducts] = useState([])
  
  useEffect(() => {
  axios.get('https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/postProdQuery_admin')
  .then(products => setProducts(products.data))
  .catch(err => console.log(err))
}, [])

  return (
    <>
  <SEO
        title="Best Marketing and Advertising Company in Bangalore"
        description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
        keywords="Standee,
        Signages,
        Canopy,
        pamphlets,
        blockout board,
        clip-on board,
        Lollipop flange board,
        gate arch,
        umbrella branding,
        pillar branding,
        led board,
        neno sign
        "
      />
      
        <AdminPanel/>
        <div className="admin-container">
            
            <h2>
            User Queries
            </h2>
        
            <div className="query-cont">
                <div className="card">
                 
                </div>
            </div>
            <div className='container'>
            <div className="row">
                    <div className="column">
                      <Link style={{color:"white"}} type="submit" name="" value="Advertisement Queries" to="/AdvertisementQuery">
                        Advertisement Queries
                      </Link>
                    </div>
                    <div className="column">
                      <Link style={{color:"#D52591E6", background:"white"}} type="submit" name="" value="Advertisement Queries" to="/ProductQuery">
                        Product Queries
                      </Link>
                    </div>
                    <div className="column">
                      <Link style={{color:"white"}} type="submit" name="" value="Advertisement Queries" to="/ServiceQuery">
                        Services Queries
                      </Link>
                    </div>
                  </div>

            <table id='users'> 
                <tr>
                  <thead>
                    <th>Name</th>
                    <th>Mail ID</th>
                    <th>Contact No.</th>
                    <th>Product Type</th>
                    <th>Budget</th>
                    <th>Details</th>
                  </thead>
                  <tbody>
                      {
                        products.map((products) => {
                          return <tr>
                            <td>{products.name}</td>
                            <td>{products.mail}</td>
                            <td>{products.phone}</td>
                            <td>{products.prodName}</td>
                            <td>{products.budget}</td>
                            <td>{products.details}</td>
                          </tr>
                        })
                      }
                  </tbody>
                </tr>
            </table>
            </div>
        </div>
        
    </>
  )
}

export default ProductQuery
