import {React, useState} from 'react'
import AdminPanel from './AdminPanel'
import "../css/Swal.css"
import Swal from "sweetalert2";
import { Link } from 'react-router-dom'

import SEO from '../components/SEO';

const CreateUser = () => {
  const[username, setUsername] = useState('');
  const[password, setPassword] = useState('');
  const[designation, setDesignation] = useState('');
  const[email, setEmail] = useState('');

  async function registerUser(ev){
      var status = 0;
      ev.preventDefault();

      if (status==0){
        const isTextValid = /^[A-Za-z\s]{4,}$/.test(username);
        const isMailValid = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/.test(email);
        const isDesignationValid = /^[A-Za-z\s]{4,}$/.test(designation);
        const isPasswordValid = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(password);
        
        // Check if the textbox has valid name or not
        if (!isTextValid){     
        Swal.fire({
            title: "Validation!",
            text: "Name Must have minimum 4 letters. You cannot use numbers or any other special characters with name.",//"Error Ocurred. Try After Sometime",
            icon: "warning",
            customClass: {
                confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
            }
        })               
        }
    
        // check if the mail has valid mail address or not
        if(!isMailValid){
          Swal.fire({
            title: "Validation!",
            text: "Mail format is wrong. For Example: name@gmail.com",//"Error Ocurred. Try After Sometime",
            icon: "warning",
            customClass: {
                confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
            }
        })                           
        }       
    
        // check if the requirement details box is valid or not
        if(!isDesignationValid){
          Swal.fire({
            title: "Validation!",
            text: "You cannot use special characters in designation name.",//"Error Ocurred. Try After Sometime",
            icon: "warning",
            customClass: {
                confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
            }
        })
        }
    
        if(!isPasswordValid){
          Swal.fire({
            title: "Validation!",
            text: "Your Password should have minimum eight characters, at least one letter and one number.",//"Error Ocurred. Try After Sometime",
            icon: "warning",
            customClass: {
                confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
            }
        })
        }
        
        
        if(isTextValid && isMailValid && isDesignationValid && isPasswordValid){
          status=1;
        }
      }
      
      
      if (status==1){
      try{
        // const response = await fetch('http://localhost:4000/users', {
      const response = await fetch('https://ap-south-1.aws.data.mongodb-api.com/app/data-zvvlf/endpoint/postusers', {
          method:'POST',
          body: JSON.stringify({username,designation,email,password}),
          headers:{
            "Content-Type": "application/json"
          },
          })
          if(response.status === 200){
            Swal.fire({
              title: "Registration!",
              text: "You Have Successfullly Registered!",
              icon: "success",
              customClass: {
                confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
              }
            });
            setUsername('');
            setPassword('');
            setDesignation('');
            setEmail('');
          }else{
            Swal.fire({
              title: "Registration!",
              text: "Oops! Registration Failed.",
              icon: "warning",
              customClass: {
                confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
              }
            });
          }
      }catch(e){
        Swal.fire({
          title: "Contact!",
          text: e,//"Error Ocurred. Try After Sometime",
          icon: "error",
          customClass: {
            confirmButton: 'custom-swal-button' // Apply custom class to the confirm button
          }
        });
      }
    } 
  }

  return (
    <>

<SEO
    title="Best Marketing and Advertising Company in Bangalore"
    description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
    keywords="Good design and feasible branding agency,
    best marketing agency,
    best marketing agency near me,
    best marketing agency in bangalore,
    best marketing companies in bangalore,
    Best marketing agency instagram accounts,
    best offline marketing companies,
    best offline marketing companies in bangalore,
    best offline marketing companies near me,
    best marketing and advertising companies"
  />
    
    <AdminPanel/>
   
    <div className="admin-container login-container">
         <h2>
          <div className='createuser' ><Link to="/Users">User's List</Link></div>
        </h2>
        <div className="enquiry-box-container w-50">
            <div className="enquiry-box">
                <div className="contactForm">
                    <h2 className='text-center'>Create User</h2>

            <form onSubmit={registerUser}>
              <div className="inputBox">
                  <i className="fa fa-user" aria-hidden="true"></i>
                  <input type="text"  
                         required="required"
                         value={username}
                          onChange={ev => setUsername(ev.target.value)}/>
                  <span>Name</span>
                </div>
                <div className="inputBox">
                <i className="fa fa-briefcase" aria-hidden="true"></i>
                  <input type="text" 
                         required="required" 
                         value={designation}
                         onChange={ev => setDesignation(ev.target.value)}/>
                  <span>Designation</span>
                </div>
                <div className="inputBox">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <input type="text"  
                         required="required"
                         value={email}
                         onChange={ev => setEmail(ev.target.value)} />
                  <span>Email</span>
                </div>
                <div className="inputBox">
                    <i className="fa fa-unlock-alt" aria-hidden="true"></i>
                  <input type="password" 
                         required="required"
                         value={password}
                         onChange={ev => setPassword(ev.target.value)} />
                  <span>Password</span>
                </div>
                <div className="inputBox">
                  <input type="submit" name="" value="Register" />
                </div>
            </form>

            </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default CreateUser
