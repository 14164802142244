import React, {useState, useEffect} from "react";
import "../css/Awards.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from "./Navbar";
import Swal from "sweetalert2";
import { redBorder } from '../functions/Validator';
import { changeBorder } from '../functions/Validator';
import ReactGA from "react-ga";
 
import SEO from '../components/SEO';
import ktcc02 from "../components/Awards/02.jpeg";
import ktcc03 from "../components/Awards/03.png";
import ktcc04 from "../components/Awards/04.jpeg";
import nom1 from "../components/Awards/nom1.jpeg";
import nom2 from "../components/Awards/nom2.jpeg";
import indiamart from "../components/Awards/indiamart.jpeg";
 
AOS.init();
const Awards = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  },[]);

  return (
    <>

<SEO
    title="Best Marketing and Advertising Company in Bangalore"
    description="Discover the leading marketing and advertising company in Bangalore. We provide top-notch services to boost your brand's visibility and growth."
    keywords="Good design and feasible branding agency,
    best marketing agency,
    best marketing agency near me,
    best marketing agency in bangalore,
    best marketing companies in bangalore,
    Best marketing agency instagram accounts,
    best offline marketing companies,
    best offline marketing companies in bangalore,
    best offline marketing companies near me,
    best marketing and advertising companies"
  />
      <Navbar/>
      
      {/* framer-motion is not required for this page */}
      <div className="main">
      <section className="featured">


            <div className="featured-content">
                <div className="featured-card">
                    <h3>Best Auto Branding Services by Karnataka Business Awards 2022</h3>
                    <a href="https://youtu.be/YUeDT8LzPC4?si=HDWcOaYFvfS3g9pl">Our Special Moment</a> 
                    <span> </span>
                    <span> </span>
                    <a href="https://youtu.be/YUeDT8LzPC4?si=HDWcOaYFvfS3g9pl" target="_blank">
                          <i class="fa-brands fa-youtube-square" aria-hidden="true"></i>
                        </a>
                </div>
                <div className="featured-card">
                    <img src= {ktcc02} alt="Featured Design"/>
                    <img src= {ktcc03} alt="Featured Design"/>
                </div>
                
            </div>

            <div className="featured-content">
                <div className="featured-card">
                <h3>Best Printing & Branding Services by Karnataka Business Awards 2021</h3>
                    <a href="https://youtu.be/ZBUuE8Yhjs8?si=t6w-XtxUsFTtZco6">Our Special Moment</a>
                    <a href="https://youtu.be/ZBUuE8Yhjs8?si=t6w-XtxUsFTtZco6" target="_blank">
                    <span> </span>
                    <span> </span>
                          <i class="fa-brands fa-youtube-square" aria-hidden="true"></i>
                        </a>
                </div>

                <div className="featured-card1">
                <img src= {ktcc04} alt="Featured Design"/>
                </div>
            </div>

        <h3>Other Nominations</h3>
                <div className="column-card">
                <img src= {nom1} alt="Featured Design"/>
                <img src= {nom2} alt="Featured Design"/>
                </div>

        <h3>We are happy to announce that we are now an Indiamart Trustseal Member!!</h3>
        <div className="column-cardd">
            <img src= {indiamart} alt="Indiamart Trustseal Member"/>
        </div>

        </section>

        </div>
    </>
  );
};

export default Awards;